import { Header } from "@/components/layout";
import type { FunctionComponent } from "../common/types";
import AnalyticsLogo from "@/assets/images/analytics_logogram.png";
import Button from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";

export const NotFoundComponent = (): FunctionComponent => {
	const navigate = useNavigate();
	return (
		<>
			<Header refreshKey={0}></Header>
			<div className=" font-semibold w-screen h-screen flex flex-col justify-center items-center ">
				<img className="w-20 h-20" src={AnalyticsLogo} alt="analytics-logo" />
				<span className="text-6xl text-grey-400 mt-8">Page Not Found</span>
				<Button
					size="sm"
					className="max-w-64 mt-8"
					variant="primary"
					onClick={() => {
						navigate({ to: "/" }).catch((error) => {
							console.error(error);
						});
					}}
				>
					Back to home
				</Button>
			</div>
		</>
	);
};
