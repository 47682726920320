import type React from "react";
import { cn } from "../../common/helper";

type VariantType = "primary" | "secondary" | "outline";
type SizeType = "xs" | "sm" | "md" | "lg";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	variant: VariantType;
	size: SizeType;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
}

const variants = {
	primary: `bg-orange-500 text-white hover:bg-orange-500/90 border border-orange-500`,
	secondary: `text-orange-500 hover:bg-orange-500/90 hover:text-white border border-orange-500`,
	outline: `text-orange-500 hover:bg-orange-500/10 hover:text-orange-500`,
};

const sizes = {
	xs: `text-sm font-medium`,
	sm: ` font-semibold`,
	md: `text-base font-semibold`,
	lg: `text-lg font-semibold`,
};

const Button: React.FC<ButtonProps> = ({
	children,
	className,
	variant = "primary",
	size = "md",
	startIcon,
	endIcon,
	...rest
}: ButtonProps) => {
	return (
		<button
			className={cn(
				`flex justify-center items-center py-2 px-4 w-full focus:outline-none rounded`,
				variants[variant],
				sizes[size],
				rest.disabled &&
					`bg-grey-100 hover:bg-grey-100 text-grey-300 hover:text-grey-300 cursor-not-allowed border border-grey-100`,
				className,
				(startIcon || endIcon) && "gap-2"
			)}
			{...rest}
		>
			{startIcon}
			{children}
			{endIcon}
		</button>
	);
};

export default Button;
