import { cn } from "@/common/helper";
import { CircleCheckIcon, CircleXIcon } from "lucide-react";

type VariantType = "success" | "error";

interface IAlert {
	variant: VariantType;
	title: string;
	description: string;
}
const variants = {
	success: `bg-green-100`,
	error: `bg-red-100`,
};
const Alert: React.FC<IAlert> = ({ title, description, variant }: IAlert) => {
	return (
		<div className={cn("flex flex-col p-4 gap-2 rounded", variants[variant])}>
			<div className="flex items-start gap-3">
				{variant === "success" && <CircleCheckIcon color="#55A61C" size={24} />}
				{variant === "error" && <CircleXIcon color="#DC0000" size={24} />}
				<div className="flex flex-col gap-1">
					<span className="font-semibold text-grey-700">{title}</span>
					<p className="text-grey-500">{description}</p>
				</div>
			</div>
		</div>
	);
};

export default Alert;
