import { cn, formatToIDR } from "@/common/helper";
import { useValidatePromo } from "@/hooks/payment-hooks";
import type { IPayment } from "@/pages/pricing";
import type { PlanType } from "@/types/master";
import type { PlansWithAddons } from "@/types/subscription";
import { AxiosError } from "axios";
import { Check, LoaderCircleIcon } from "lucide-react";
import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../ui/button";
import { InputForm } from "../ui/input";
import Modal from "../ui/modal";

interface IConfirmCheckout {
	isOpen: boolean;
	onClose: () => void;
	handleSubmit: (promoCode?: string) => void;
	payload: IPayment;
	plans: PlansWithAddons;
}

const validatePromoSchema = yup.object({});
const ConfirmCheckoutModal: React.FC<IConfirmCheckout> = ({
	isOpen,
	onClose,
	handleSubmit,
	payload,
	plans,
}) => {
	const methods = useForm({});
	let planType: PlanType = "BASIC";
	const [agree, setAgree] = useState(false);
	const [promoAmount, setPromoAmount] = useState(0);
	const [validatePromoIsLoading, setValidatePromoIsLoading] = useState(false);
	const [promoMessage, setPromoMessage] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const validatePromo = useValidatePromo();

	const subscriptionPlan = plans.subscriptionPlan.find(
		(x) => x.id == payload.planID
	);
	const addOnPlan = plans.addonPlan.find((x) => x.id == payload.addon?.id);
	if (!subscriptionPlan) return <></>;
	let subtotalPrice = 0;
	if (payload.planID && !payload.addon) {
		subtotalPrice += subscriptionPlan.pricePerMonth;
	}
	if (addOnPlan && payload.addon) {
		planType = "ADDON";
		subtotalPrice += payload.addon?.quantity * addOnPlan.price;
	}
	const taxPrice = (subtotalPrice - promoAmount) * 0.11;

	const validatePromoSubmit: SubmitHandler<
		yup.InferType<typeof validatePromoSchema>
	> = () => {
		const payload: { code: string; amount: number; type: PlanType } = {
			code: promoCode.toUpperCase(),
			amount: subtotalPrice,
			type: planType,
		};

		validatePromo.mutate(payload, {
			onSuccess: (data) => {
				setPromoAmount(data.promoAmount);
				setPromoMessage(
					`Congratulations 🎉  ! You will receive ${formatToIDR(data.promoAmount)} discount!`
				);
			},
			onError: (error: unknown) => {
				setPromoAmount(0);
				if (error instanceof AxiosError) {
					console.log("error", error);
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					setPromoMessage(error.response?.data.message);
				} else {
					console.error("unexpected error:", error);
				}
			},
			onSettled: () => {
				setValidatePromoIsLoading(false);
			},
		});
	};

	const handleValidatePromo = (): void => {
		methods
			.handleSubmit(validatePromoSubmit)()
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<Modal
			isOpen={isOpen}
			withTitleIcon={false}
			onClose={() => {
				onClose();
			}}
			hasCloseIcon={false}
			title="Complete your purchase"
			onSubmit={() => {
				handleSubmit(promoAmount > 0 ? promoCode : undefined);
			}}
			submitComponent={
				<Button
					size="md"
					variant="primary"
					onClick={() => {
						handleSubmit(promoAmount > 0 ? promoCode : undefined);
					}}
					disabled={!agree}
				>
					Checkout Now
				</Button>
			}
		>
			<div className="w-full lg:min-w-[400px] text-grey-800">
				<div className="mb-4 border-grey-150 border rounded-xl p-4">
					{payload.planID && !payload.addon && (
						<div className="flex justify-between mb-2">
							<span>Monthly Subscription</span>
							<span>{formatToIDR(subscriptionPlan.pricePerMonth)}</span>
						</div>
					)}
					{payload.addon && addOnPlan && (
						<div className="flex justify-between mb-2">
							<span>Add-On Credit Analysis</span>
							<span>
								{formatToIDR(payload.addon?.quantity * addOnPlan.price)}
							</span>
						</div>
					)}

					{promoAmount && promoAmount > 0 ? (
						<div className="flex justify-between mb-2">
							<span>Discount</span>
							<span className="text-green-400 font-semibold">
								- {formatToIDR(promoAmount)}
							</span>
						</div>
					) : null}
					<div className="flex justify-between mb-2">
						<span>VAT 11%</span>
						<span>{formatToIDR(taxPrice)}</span>
					</div>
					<div className="flex justify-between border-t border-grey-150 pt-2">
						<span className="font-semibold">Total</span>
						<span className="font-semibold">
							{formatToIDR(subtotalPrice + taxPrice - promoAmount)}
						</span>
					</div>
				</div>

				<div className="my-2">
					<FormProvider {...methods}>
						<div className="flex flex-row gap-2 center">
							<div className="flex-1">
								<InputForm
									type="text"
									placeholder="Promo Code"
									value={promoCode}
									onChange={(event) => {
										setPromoCode(event.target.value.toUpperCase());
										setPromoMessage("");
										setPromoAmount(0);
									}}
									inputClassname={promoCode == "" ? "normal-case" : "uppercase"}
									onKeyDown={(event) => {
										if (event.key === "Enter") {
											handleValidatePromo();
										}
									}}
								/>
							</div>
							<Button
								size="md"
								variant="primary"
								className="max-w-24 lg:max-w-28 h-[41px] mt-2"
								onClick={handleValidatePromo}
								disabled={promoCode == "" || validatePromoIsLoading}
								startIcon={
									validatePromoIsLoading && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								Apply
							</Button>
						</div>
						{promoMessage && promoMessage.length > 0 && (
							<span
								className={cn(
									"mt-10 text-sm",
									promoAmount == 0 ? "text-red-400" : "text-green-400"
								)}
							>
								{promoMessage}
							</span>
						)}
					</FormProvider>
				</div>
				<div className="mb-4 mt-4">
					<label className="flex items-center">
						<input
							type="checkbox"
							className="hidden"
							checked={agree}
							onChange={() => {
								setAgree(!agree);
							}}
						/>

						<span
							className={`w-4 h-4 inline-block mr-2 border-2 rounded-sm ${
								agree ? "bg-orange-500 border-orange-500" : "border-orange-500"
							} flex justify-center items-center`}
						>
							{agree && <Check className="w-3 h-3 text-white" />}
						</span>
						<span>
							Agree to our{" "}
							<a
								href="/terms-and-conditions"
								target="_blank"
								className="underline"
							>
								terms & conditions
							</a>{" "}
							and{" "}
							<a href="/privacy-policy" target="_blank" className="underline">
								privacy policy
							</a>
							.
						</span>
					</label>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmCheckoutModal;
