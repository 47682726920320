import EmailVerification from "@/pages/auth/email-verification";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/email-verification")({
	component: EmailVerification,
	beforeLoad: ({ location }) => {
		if (tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login", search: location.href });
		}
	},
});
