// components/PaymentComponent.tsx
import { useGeneratePayment } from "@/hooks/payment-hooks";
import type { IPayment } from "@/pages/pricing";
import { getAllPlans } from "@/services/subscription-services";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import ConfirmCheckoutModal from "../modal/confirm-checkout";
import { useState } from "react";

declare const snap: {
	pay: (
		token: string,
		options: {
			onSuccess: (result: unknown) => void;
			onPending: (result: unknown) => void;
			onError: (result: unknown) => void;
			onClose: () => void;
		}
	) => void;
};

interface PaymentComponentProps {
	payload: IPayment;
	children: ((isPending: boolean) => React.ReactNode) | React.ReactNode;
}

const PaymentComponent: React.FC<PaymentComponentProps> = ({
	payload,
	children,
}) => {
	const navigate = useNavigate();
	const generatePayment = useGeneratePayment();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { data: planData, isPending: planDataIsPending } = useQuery({
		queryKey: ["get-plan-datas"],
		queryFn: async () => {
			const resp = await getAllPlans();
			return resp;
		},
		enabled: true,
	});
	const handlePayment = (token: string): void => {
		try {
			snap.pay(token, {
				onSuccess: (result) => {
					navigate({ to: "/discover" }).catch((error) => {
						console.error(error);
					});
					console.log(result);
				},
				onPending: (result) => {
					alert("Waiting for your payment!");
					console.log(result);
				},
				onError: (result) => {
					alert("Payment failed!");
					console.log(result);
				},
				onClose: () => {
					alert("You closed the popup without finishing the payment");
				},
			});
		} catch (error) {
			console.error(error);
		}
	};

	const generatePaymentToken = (promoCode?: string): void => {
		generatePayment.mutate(
			{ ...payload, code: promoCode },
			{
				onSuccess: (result) => {
					if (result && result.token) handlePayment(result.token);
					else {
						toast.success("Your order was successful.🎉");
						navigate({ to: "/discover" }).catch((error) => {
							console.error(error);
						});
					}
				},
				onError: (error: unknown) => {
					if (error instanceof AxiosError) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
						toast.error(error.response?.data.message);
					} else {
						console.error("Unexpected error:", error);
					}
				},
			}
		);
	};

	return (
		<div
			onClick={() => {
				setIsModalOpen(!isModalOpen);
			}}
			style={{ pointerEvents: generatePayment.isPending ? "none" : "auto" }}
		>
			{planData && isModalOpen && !planDataIsPending && (
				<ConfirmCheckoutModal
					plans={planData}
					isOpen={isModalOpen}
					onClose={(): void => {
						setIsModalOpen(false);
					}}
					handleSubmit={(promoCode?: string) => {
						setIsModalOpen(false);
						generatePaymentToken(promoCode);
					}}
					payload={payload}
				></ConfirmCheckoutModal>
			)}
			{typeof children === "function"
				? children(generatePayment.isPending)
				: children}
		</div>
	);
};

export default PaymentComponent;
