import type React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Label from "../ui/label";
import { cn } from "@/common/helper";

interface IInputRadio extends React.InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	label?: string;
	required?: boolean;
	values: Array<{ value: number; label: string }>;
}
const InputRadio: React.FC<IInputRadio> = ({
	name = "",
	label = "",
	required = false,
	values = [],
	...rest
}) => {
	const methods = useFormContext();

	return (
		<Controller
			control={methods.control}
			name={name}
			defaultValue=""
			render={({ field, fieldState }) => (
				<div className="flex flex-col gap-2">
					<div className="flex items-start gap-1">
						<Label>{label}</Label>
						{required && <Label className=" text-red-600">*</Label>}
					</div>
					<div>
						<div className="flex items-center gap-2">
							{values.map((data, index) => (
								<label key={index}>
									<input
										{...field}
										{...rest}
										type="radio"
										// defaultValue={data.value}
										className="peer hidden"
										name={name}
										checked={data.value === field.value}
										// checked={field.value === data.value}
										onChange={() => {
											field.onChange(data.value);
										}}
										value={data.value}
									/>
									<div
										className={cn(
											"hover:bg-gray-50 flex items-center justify-between text-grey-500 font-semibold px-3 py-1 w-fit border-2 rounded-[100px] cursor-pointer text-sm border-gray-200 group peer-checked:border-orange-700 peer-checked:bg-orange-200 peer-checked:text-orange-700"
											// fieldState.invalid &&
											// 	"border border-red-500"
										)}
									>
										{data.label}
									</div>
								</label>
							))}
						</div>
						{fieldState.invalid && (
							<small className="text-sm text-red-600">
								{fieldState.error?.message}
							</small>
						)}
					</div>
				</div>
			)}
		/>
	);
};

export default InputRadio;
