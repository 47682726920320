import type { FunctionComponent } from "@/common/types";
import { Layout } from "@/components/layout";
import ChangePasswordModal from "@/components/modal/change-password";
import CheckPasswordModal from "@/components/modal/check-password";
import Button from "@/components/ui/button";
import { getAccount } from "@/services/account-services";
import { useQuery } from "@tanstack/react-query";
import { PencilIcon } from "lucide-react";
import { useState } from "react";

function MyAccount(): FunctionComponent {
	const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);

	const { data, isPending } = useQuery({
		queryKey: ["get-account"],
		queryFn: async () => {
			const resp = await getAccount();
			return resp;
		},
	});

	return (
		<Layout isPending={isPending}>
			<div className="flex flex-col gap-2 sm:gap-4">
				<span className="text-lg font-semibold">Profile Information</span>
				<hr />
			</div>

			<div className="flex flex-col gap-2 sm:gap-4 pt-2">
				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Full Name
					</span>
					<span className="font-normal capitalize flex-1">
						{data ? data.name : "-"}
					</span>
				</div>

				<div className="w-fit">
					<Button
						variant="secondary"
						size="xs"
						startIcon={<PencilIcon size={16} />}
						onClick={() => {
							setConfirmPasswordModal(true);
						}}
					>
						Edit Profile
					</Button>
				</div>
			</div>

			<div className="flex flex-col gap-2 sm:gap-4 pt-4">
				<span className="text-lg font-semibold">Account Security</span>
				<hr />
			</div>

			<div className="flex items-center pt-2">
				<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
					Email
				</span>
				<span className="flex-1 flex items-center space-x-2">
					<span>{data ? data.email : "-"}</span>
					<span className="text-green-400 font-semibold hidden sm:block w-fit">
						Verified
					</span>
				</span>
			</div>
			<div className="flex items-center pt-2">
				<span className="text-grey-500 font-semibold  flex-1 max-w-[250px]">
					Password
				</span>
				<span className="font-normal flex-1">•••••••••</span>
			</div>

			<div className="w-fit pt-2">
				<Button
					variant="secondary"
					size="xs"
					onClick={() => {
						setChangePasswordModal(true);
					}}
				>
					Change Password
				</Button>
			</div>
			{/* Render Modal */}
			<CheckPasswordModal
				isOpen={confirmPasswordModal}
				onClose={() => {
					setConfirmPasswordModal(false);
				}}
				href="/settings/my-account/edit-profile"
			/>

			<ChangePasswordModal
				isOpen={changePasswordModal}
				onClose={() => {
					setChangePasswordModal(false);
				}}
			/>
		</Layout>
	);
}

export default MyAccount;
