import type { FunctionComponent } from "@/common/types";
import type React from "react";
import AuthImage from "@/assets/images/auth-image.jpg";
import Footer from "../Footer";

interface IAuthLayout {
	children: React.ReactNode;
}

const AuthLayout = ({ children }: IAuthLayout): FunctionComponent => {
	return (
		<div className="flex items-center justify-center min-h-screen">
			{/* Business Profile Section */}
			<div className="flex-col basis-1 grow bg-grey-100 hidden sm:flex h-full min-h-screen">
				<div className="relative w-full h-full min-h-screen">
					<div className="flex flex-col justify-center gap-4 px-4 lg:px-10 xl:px-20 absolute bottom-0 w-full md:h-full xl:h-1/2 bg-gradient-to-t from-black to-transparent">
						<span className="text-white font-semibold text-4xl w-[80%]">
							Work with the right creators all the time.
						</span>
						<span className="text-white font-light text-xl w-[80%]">
							Get access to comprehensive creator data for your successful
							influencer marketing campaign.
						</span>
					</div>
					<img
						src={AuthImage}
						alt="icon"
						className="object-cover object-right w-full h-full min-h-screen"
					/>
				</div>
			</div>

			{/* Content */}
			<div className="flex flex-col basis-1 grow bg-white items-center justify-between sm:justify-center h-full min-h-screen">
				<div className="flex flex-grow flex-col max-w-screen-sm gap-8 w-full self-center h-full">
					{children}
				</div>
				<div className="flex flex-col justify-end">
					<Footer isSticky={false}></Footer>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
