import { useState } from "react";
import { usePopper } from "react-popper";
import styles from "./tooltip.module.css";

interface TooltipProps {
	children: React.ReactNode;
	tooltipContent: React.ReactNode;
}
const Tooltip: React.FC<TooltipProps> = ({ children, tooltipContent }) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [referenceElement, setReferenceElement] =
		useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	);
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(
		referenceElement,
		popperElement,
		{
			placement: "top",
			modifiers: [
				{ name: "arrow", options: { element: arrowElement } },
				{ name: "offset", options: { offset: [0, 8] } },
			],
		}
	);

	return (
		<div>
			<div
				ref={setReferenceElement}
				onMouseEnter={() => {
					setShowTooltip(true);
				}}
				onMouseLeave={() => {
					setShowTooltip(false);
				}}
				style={{ display: "inline-block" }}
			>
				{children}
			</div>
			{showTooltip && (
				<div
					ref={setPopperElement}
					style={popperStyles["popper"]}
					{...attributes["popper"]}
				>
					<div
						className="tooltip-content"
						style={{
							background: "#5b5c5ec9",
							color: "white",
							padding: "8px 16px",
							borderRadius: "4px",
							maxWidth: "376px",
						}}
					>
						{tooltipContent}
					</div>
					<div
						ref={setArrowElement}
						style={popperStyles["arrow"]}
						{...attributes["arrow"]}
						className={styles["tooltipArrow"]}
					/>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
