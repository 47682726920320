import type { ListAnalytics, ListItem } from "@/services/master-services";
import CoinIcon from "@/assets/icons/coin-icon.png";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import dayjs from "dayjs";
import type { AnalyticsSource, SocialMediaPlatform } from "@/types/master";
import { InfoIcon, Percent, TriangleAlert, UserSearch } from "lucide-react";
import { useCreditStore } from "@/store/credit-stores";
import { hasAnalyticCredit } from "@/common/helper";
import { useFeaturedCreatorStore } from "@/store/featured-creator-store";

function groupAndSortByDay(
	data: Array<ListItem>
): Record<string, Array<ListItem>> {
	const groupedData: Record<string, Array<ListItem>> = {};

	data.forEach((user) => {
		const date = new Date(user.updatedAt);
		const day = date.toISOString().split("T")[0]; // Extract the day in 'YYYY-MM-DD' format

		if (day) {
			// Ensure day is defined
			if (!(day in groupedData)) {
				groupedData[day] = [];
			}
			(groupedData[day] as Array<ListItem>).push(user);
		}
	});

	// Sort each day's entries by hours descending
	Object.keys(groupedData).forEach((day) => {
		(groupedData[day] as Array<ListItem>).sort((a, b) => {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		});
	});

	return groupedData;
}
interface IRecentAnalysis {
	listData: ListAnalytics;
	handleSearchUser: (
		username: string,
		platform: SocialMediaPlatform,
		analyzeSource: AnalyticsSource
	) => void;
}

const RecentAnalysis: React.FC<IRecentAnalysis> = ({
	listData,
	handleSearchUser,
}) => {
	const creditInfo = useCreditStore((state) => state.creditInfo);
	const listFreeCreatorStore = useFeaturedCreatorStore();
	const renderButtonAnalyze = (user: ListItem): JSX.Element => {
		if (user.progress === 300) {
			console.log(user);
			if (
				listFreeCreatorStore.freeCreatorList.some(
					(x) =>
						x.username == user.username &&
						x.platform.toLowerCase() == user.platform.toLowerCase()
				)
			) {
				return (
					<button
						className="flex justify-between gap-2 items-center text-orange-700 font-medium py-1 px-2 pl-3 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 rounded-2xl"
						onClick={() => {
							handleSearchUser(user.username, user.platform, "RECENT");
						}}
					>
						<span className="">Analyze</span>
						<span className="flex items-center text-[14px] py-1 px-2 text-white font-medium justify-center bg-orange-500 rounded-2xl">
							FREE
						</span>
					</button>
				);
			} else if (hasAnalyticCredit(creditInfo))
				return (
					<button
						className="flex justify-center items-center text-orange-700 font-medium gap-2 py-1 px-2 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 hover:rounded-3xl rounded-2xl"
						onClick={() => {
							handleSearchUser(user.username, user.platform, "RECENT");
						}}
					>
						<div className="flex items-center justify-center w-4 h-4 bg-orange-500 rounded-full">
							<img src={CoinIcon} alt="" />
						</div>
						<span className="">Analyze</span>
					</button>
				);
			return (
				<button
					className="flex flex-row flex-nowrap justify-center
					items-center text-green-500 font-semibold gap-2 py-1 px-2 w-full border border-green-500 focus:outline-none hover:bg-green-200 hover:rounded-3xl rounded-2xl"
					onClick={() => {
						handleSearchUser(user.username, user.platform, "RECENT");
					}}
				>
					<div className="flex items-center justify-center w-5 h-5 bg-green-500 rounded-full">
						<Percent size={12} color="#fff"></Percent>
					</div>
					<span className="whitespace-nowrap">ER Calculator</span>
				</button>
			);
		} else if (user.progress === 0) {
			return (
				<button
					disabled
					className="flex flex-nowrap justify-center items-center cursor-progress text-grey-200 font-semibold gap-2 w-full"
				>
					Preparing{" "}
					{user.attemptsMade >= 3 ? (
						<TriangleAlert color="#FAA61A" size={16}></TriangleAlert>
					) : (
						<InfoIcon size={16} />
					)}
				</button>
			);
		}

		return (
			<button
				disabled
				className="flex justify-center items-center text-[red] font-semibold gap-2 py-1 px-4 w-full border border-[red] focus:outline-none rounded-2xl"
			>
				<span className="">Failed</span>
			</button>
		);
	};

	const renderListData = (listData: ListAnalytics): JSX.Element => {
		const groupedData = groupAndSortByDay(listData.data);
		return (
			<>
				{Object.entries(groupedData).map(([day, users], index) => (
					<div key={index}>
						<span className="text-sm text-grey-400 font-medium">
							{dayjs(day).format("MMMM DD, YYYY")}
						</span>
						<ul className="flex flex-col gap-2 mt-2">
							{users.map((user) => (
								<li
									className="flex justify-between items-center gap-2 my-2 sm:my-0"
									key={user.uuid}
								>
									<div className="flex items-center gap-2">
										<div>
											<img
												src={
													user.platform == "instagram" ? InstaIcon : TiktokIcon
												}
												alt="insta-icon"
											/>
										</div>
										<div className="flex gap-0 sm:gap-2 flex-col sm:flex-row">
											{user.name && (
												<span className=" text-grey-800 font-medium">
													{user.name}
												</span>
											)}
											<span className=" text-grey-400 font-medium">
												@{user.username}
											</span>
										</div>
									</div>
									<div>{renderButtonAnalyze(user)}</div>
								</li>
							))}
						</ul>
					</div>
				))}
			</>
		);
	};

	return (
		<div className="flex flex-col py-5 px-5 sm:px-7 gap-4 bg-grey-100 text-grey-800 rounded-xl shadow">
			<div className="flex items-center gap-5">
				<span className="font-semibold">Recent Analysis</span>
			</div>

			<div className="flex flex-col gap-2 xl:gap-4">
				{listData && listData.data.length > 0 ? (
					renderListData(listData)
				) : (
					<div className="flex flex-col justify-center items-center xl:py-16">
						<span className="hidden xl:block">
							<UserSearch size={48} color="#808080"></UserSearch>
						</span>
						<span className="xl:hidden">
							<UserSearch size={20} color="#808080"></UserSearch>
						</span>
						<span className="text-grey-400 xl:my-4">
							No Recent Analysis Data
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecentAnalysis;
