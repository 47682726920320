import { Layout } from "@/components/layout";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useAccount } from "@/hooks/account-hooks";
import { getAccount } from "@/services/account-services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { ChevronLeftIcon } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

export const editAccountSchema = yup.object({
	name: yup.string().required(),
});

const EditProfile: React.FC = () => {
	const navigate = useNavigate();
	const account = useAccount();

	const { data } = useQuery({
		queryKey: ["get-account"],
		queryFn: async () => {
			const response = await getAccount();
			return response;
		},
	});

	const methods = useForm<yup.InferType<typeof editAccountSchema>>({
		resolver: yupResolver(editAccountSchema),
		defaultValues: {
			name: data?.name ?? "",
		},
	});

	useEffect(() => {
		if (data) {
			methods.reset({ name: data.name });
		}
	}, [data, methods]);

	const onSubmit = (
		formData: yup.InferType<typeof editAccountSchema>
	): void => {
		account.mutate(formData, {
			onSuccess: () => {
				toast.success("Account changed!");
				navigate({ to: "/settings/my-account" }).catch((error) => {
					console.error(error);
				});
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message);
				} else {
					console.error("Unexpected error:", error);
				}
			},
		});
	};

	return (
		<Layout>
			<div className="flex flex-col gap-4">
				<Link
					to="/settings/my-account"
					className="flex items-center gap-1 text-lg font-semibold hover:text-orange-500"
				>
					<ChevronLeftIcon />
					<span>Back</span>
				</Link>
				<hr />
			</div>

			<span className="font-semibold text-lg my-4 sm:my-0">Edit Profile</span>

			<div className="flex flex-col gap-4">
				<FormProvider {...methods}>
					<InputForm name="name" label="Name" placeholder="Your Name" />
					<div className="flex justify-end">
						<div className="flex gap-2">
							<Button
								variant="outline"
								size="sm"
								onClick={() => {
									navigate({ to: "/settings/my-account" }).catch((error) => {
										console.error(error);
									});
								}}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								size="sm"
								className="w-full whitespace-nowrap"
								onClick={() => {
									methods
										.handleSubmit(onSubmit)()
										.catch((error) => {
											console.error(error);
										});
								}}
							>
								Save Changes
							</Button>
						</div>
					</div>
				</FormProvider>
			</div>
		</Layout>
	);
};

export default EditProfile;
