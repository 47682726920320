import { formatToIDR } from "@/common/helper";
import { Layout } from "@/components/layout";
import PaymentComponent from "@/components/subscription/midtrans";
import Button from "@/components/ui/button";
import Modal from "@/components/ui/modal";
import { useGeneratePayment, useRequestFreeTrial } from "@/hooks/payment-hooks";
import { getAccount } from "@/services/account-services";
import { getSubscriptionInfo } from "@/services/subscription-services";
import tokenServices from "@/services/token-services";
import { useCreditStore } from "@/store/credit-stores";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { CheckCircle2Icon, InfoIcon, LoaderCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export interface IPayment {
	planID: number;
	addon?: {
		id: number;
		quantity: number;
	};
	code?: string;
}

const Pricing: React.FC = () => {
	const generatePayment = useGeneratePayment();
	const navigate = useNavigate();
	const [showFreeTrialPopup, setShowFreeTrialPopup] = useState(false);
	const [showUnderReviewPopup, setShowUnderReviewPopup] = useState(false);

	const user = tokenServices.getUser();
	const planInfo = tokenServices.getPlanInfo() || { status: "" };

	const creditInfo = useCreditStore((state) => state.creditInfo);

	const isLoggedIn = user && user.email ? true : false;
	const { data: account } = useQuery({
		queryKey: ["get-account"],
		queryFn: async () => {
			const response = await getAccount();
			return response;
		},
		enabled: isLoggedIn,
	});

	const { data: subscriptionInfo, isPending: isSubscriptionInfoPending } =
		useQuery({
			queryKey: ["get-subscription-info"],
			queryFn: async () => {
				const resp = await getSubscriptionInfo();
				if (resp.status !== "ACTIVE") return null;
				return resp;
			},
			enabled: isLoggedIn,
		});
	useEffect(() => {
		if (user?.email) {
			const scriptTag = document.createElement("script");
			scriptTag.src = import.meta.env["VITE_SB_MIDTRANS_URL"] as string;
			scriptTag.dataset["clientKey"] = import.meta.env[
				"VITE_SB_MIDTRANS_KEY"
			] as string;

			document.body.append(scriptTag);

			return () => {
				scriptTag.remove();
			};
		}
		return;
	}, [user?.email]);

	const handleTrial = (): void => {
		if (account) {
			setShowFreeTrialPopup(true);
			return;
		}

		toast.error("You should register to request trial");
		navigate({ to: "/register" }).catch((error) => {
			console.error(error);
		});
	};

	const requestFreeTrial = useRequestFreeTrial();
	return (
		<Layout withSidebar={false}>
			<Modal
				isOpen={showFreeTrialPopup}
				title="Request Free Trial?"
				description="10 Creator Analytics Credit "
				onClose={() => {
					setShowFreeTrialPopup(false);
				}}
				children={
					<span className="text-grey-400">
						Our team will reviewing your request.
					</span>
				}
				submitText="Yes"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					requestFreeTrial.mutate([], {
						onSuccess: () => {
							toast.success("Requested for Free Trial");
							navigate({ to: "/" }).catch((error) => {
								console.error(error);
							});
						},
						onError: (error: unknown) => {
							if (error instanceof AxiosError) {
								if (error.response?.status == 422) {
									setShowUnderReviewPopup(true);
									// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
								} else toast.error(error.response?.data.message);
							} else {
								console.error("Unexpected error:", error);
							}
						},
						onSettled: () => {
							setShowFreeTrialPopup(false);
						},
					});
				}}
			></Modal>
			<Modal
				isOpen={showUnderReviewPopup}
				title="Your free trial request under review"
				description="Our team still reviewing your request, or you can subscribe"
				onClose={() => {
					setShowUnderReviewPopup(false);
				}}
				children={<></>}
				submitText="Pricing Plan"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					setShowUnderReviewPopup(false);
					navigate({ to: "/pricing" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
			<div className="flex flex-col items-center justify-center my-10 gap-20 w-full pb-10">
				<div className="flex flex-col items-center justify-center gap-4 mx-2">
					<h1 className="text-center text-[54px] font-semibold text-grey-800">
						Find The Perfect Plan
					</h1>
					<span className="font-medium text-2xl text-grey-400 text-center">
						Get started today. Debit, credit card, or e-wallet needed for
						subscription.
					</span>
				</div>

				<div className="flex flex-col sm:flex-row items-center justify-center gap-[30px]">
					<div className="flex flex-col bg-white w-[340px] text-grey-800 p-5 border border-grey-150 rounded-2xl h-72 justify-between">
						<div className="flex flex-col">
							<span className="text-2xl">7-days Free Trial</span>
							<span className="text-3xl font-medium">{formatToIDR(0)}</span>
						</div>

						<div className="flex items-center gap-1">
							<CheckCircle2Icon size={18} fill="#398270" stroke="#FFF" />
							<span className="font-medium">10 Creator Analytics Credit</span>
						</div>

						<Button
							size="sm"
							variant="secondary"
							onClick={handleTrial}
							disabled={
								isLoggedIn ? creditInfo?.subscriptionPlan !== undefined : false
							}
						>
							Request Trial
						</Button>
					</div>

					<div className="flex flex-col bg-white w-[340px] text-grey-800 p-5 border border-grey-150 gap-10 rounded-2xl shadow-xl justify-around">
						<div className="flex flex-col">
							<span className="text-2xl">Monthly Subscription</span>
							<span className="text-3xl font-medium">
								{formatToIDR(650_000)}
							</span>
						</div>

						<div className="flex flex-col gap-2">
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon size={18} fill="#398270" stroke="#FFF" />
								<span>100 Monthly Creator Analytics Credit</span>
							</div>
							<div className="flex items-start font-medium gap-1">
								<CheckCircle2Icon
									size={18}
									fill="#398270"
									stroke="#FFF"
									className="mt-1"
								/>
								<span>500 Monthly Engagement Rate Calculator</span>
							</div>
						</div>

						<PaymentComponent payload={{ planID: 1 }}>
							<Button
								size="sm"
								variant="primary"
								disabled={
									generatePayment.isPending ||
									(!isSubscriptionInfoPending &&
										planInfo.status === "ACTIVE" &&
										subscriptionInfo?.subscriptionPlan.name == "BASIC")
								}
								startIcon={
									generatePayment.isPending &&
									!isSubscriptionInfoPending &&
									planInfo.status === "ACTIVE" &&
									subscriptionInfo?.subscriptionPlan.name == "BASIC" && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								{!isSubscriptionInfoPending &&
								planInfo.status === "ACTIVE" &&
								subscriptionInfo?.subscriptionPlan.name == "BASIC"
									? "Subscribed"
									: "Subscribe"}
							</Button>
						</PaymentComponent>
					</div>

					<div className="flex flex-col bg-white w-[340px] text-grey-800 p-5 border border-grey-150 rounded-2xl h-72 justify-between">
						<div className="flex flex-col">
							<span className="text-2xl">Add-On Credit*</span>
							<span className="text-3xl font-medium">
								{formatToIDR(450_000)}
							</span>
						</div>

						<div className="flex flex-col gap-2">
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon size={18} fill="#398270" stroke="#FFF" />
								<span>100 Monthly Creator Analytics Credit</span>
							</div>
						</div>

						<div className="flex flex-col items-center justify-center">
							<span className="text-[14px] mb-2">
								*Only When Your <strong>Monthly subscription is Active</strong>
							</span>

							<Button
								size="sm"
								variant="primary"
								disabled={
									generatePayment.isPending ||
									(!isSubscriptionInfoPending &&
										planInfo.status !== "ACTIVE") ||
									subscriptionInfo?.subscriptionPlan.name !== "BASIC"
								}
								onClick={() => {
									navigate({ to: "/settings/my-subscription" }).catch(
										(error) => {
											console.error(error);
										}
									);
								}}
								startIcon={
									generatePayment.isPending &&
									planInfo.status === "ACTIVE" && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								Add
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Pricing;
