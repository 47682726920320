import { InfoIcon } from "lucide-react";
import BarChart from "../charts/bar";
import DoughnutCart from "../charts/doughnut";
import Tooltip from "../ui/tooltip";

export type TAudienceDemographyLocation = {
	city: string;
	province: string | null;
	country: string | null;
	value: number;
};
export type TAudienceDemographyAge = Array<number>;

export type TAudienceDemographyGender = {
	male: number;
	female: number;
};
interface IAudienceDemography {
	locationData?: Array<TAudienceDemographyLocation>;
	genderData?: TAudienceDemographyGender;
	ageData?: TAudienceDemographyAge;
}

const topLocationDatasets = [
	{
		city: "Jakarta Selatan",
		province: "",
		country: "",
		value: 33.5,
	},
	{
		city: "Pekan Baru",
		province: "",
		country: "",
		value: 26.6,
	},
	{
		city: "Bandung",
		province: "",
		country: "",
		value: 12.1,
	},
	{
		city: "Bali",
		province: "",
		country: "",
		value: 5.3,
	},
	{
		city: "Depok",
		province: "",
		country: "",
		value: 2.5,
	},
];
const tooltip = [
	"The percentage distribution of the audience based on gender.",
	"The percentage distribution of the audience across different age groups.",
	"The most common locations where the audience is based.",
];
const AudienceDemography: React.FC<IAudienceDemography> = ({
	locationData = topLocationDatasets,
	genderData = { male: 32.7, female: 100 - 32.7 },
	ageData = [5.26, 42.11, 31.58, 15.79, 3.26, 1.2, 0.8],
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center gap-2">
				<div className="h-4 border-l-4 border-orange-500 rounded-3xl"></div>
				<span className="text-lg font-semibold">Audience Demography</span>
			</div>

			<div className="flex flex-col md:flex-row gap-4 flex-wrap">
				<div className="flex flex-col w-full lg:w-[calc(33.33%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-4 bg-white rounded-lg">
					<Tooltip tooltipContent={tooltip[0]}>
						<div className="flex items-center text-grey gap-2">
							<span>Gender</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<div className="flex w-full justify-center max-h-[300px]">
						<DoughnutCart male={genderData.male} female={genderData.female} />
					</div>
				</div>
				<div className="flex flex-col w-full lg:w-[calc(33.33%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-4 bg-white rounded-lg">
					<Tooltip tooltipContent={tooltip[1]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Age</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<div className="flex w-full justify-center items-center max-h-[300px] h-[300px]">
						<BarChart
							indexAxis="horizontal"
							datasets={ageData}
							stacked={false}
							labels={[
								"13-17",
								"18-24",
								"25-34",
								"34-44",
								"45-54",
								"55-64",
								"65+",
							]}
						/>
					</div>
				</div>
				<div className="flex flex-col w-full lg:w-[calc(33.33%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-4 bg-white rounded-lg">
					<Tooltip tooltipContent={tooltip[2]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Top Locations</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<div className="flex w-full justify-center items-center max-h-[350px] h-[300px]">
						<BarChart
							indexAxis="vertical"
							labels={locationData
								.slice(0, 5)
								.map((x) => x.city ?? x.province ?? x.country)}
							datasets={locationData.slice(0, 5).map((x) => x.value)}
						/>
					</div>
				</div>
			</div>

			{/* <div className="flex flex-col md:flex-row gap-4">
			<div className="flex flex-col w-full md:w-1/2 p-4 gap-4 bg-white rounded-lg">
					<span className="text-grey-500 font-semibold">Top Languages</span>
					<BarChart
						indexAxis="vertical"
						labels={["Top Languages"]}
						datasets={topGenderDatasets}
					/>
				</div>
				</div> */}
		</div>
	);
};

export default AudienceDemography;
