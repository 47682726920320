import MyAccount from "@/pages/settings/my-account";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/settings/my-account")({
	component: MyAccount,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
