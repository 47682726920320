import PublicSearch from "@/pages/public/search";
import tokenServices from "@/services/token-services";
import type { SocialMediaPlatform } from "@/types/master";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/public/search")({
	component: PublicSearch,
	beforeLoad: () => {
		if (tokenServices.getLocalAccessToken()) {
			throw redirect({ to: "/discover" });
		}
	},
	validateSearch: (
		search: Record<string, unknown>
	): { username: string; platform: SocialMediaPlatform } => {
		return {
			username: search["username"] as string,
			platform: search["platform"] as SocialMediaPlatform,
		};
	},
});
