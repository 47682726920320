import { formatToIDR } from "@/common/helper";
import PaymentComponent from "@/components/subscription/midtrans";
import Button from "@/components/ui/button";
import { CheckCircle2Icon, LoaderCircleIcon } from "lucide-react";

const NoSubscription: React.FC<{
	generatePaymentIsPending: boolean;
}> = ({ generatePaymentIsPending }) => {
	return (
		<div className="flex flex-col w-full xl:w-2/3 gap-10 p-4 sm:p-6 mt-4 bg-white border border-grey-100 rounded-xl">
			<span>You don't have active subscription</span>
			<div className="flex flex-col justify-center gap-4 items-center">
				<span className="text-center">
					Get started today. Debit, credit card, or e-wallet needed for
					subscription.
				</span>
				<div className="flex flex-col w-full p-4 sm:p-6 gap-10 bg-white border border-grey-100 shadow rounded-xl">
					<div className="flex flex-col">
						<span className="text-3xl text-grey-500 text-center sm:text-start">
							Monthly Subscription
						</span>
						<span className="text-3xl font-medium text-center sm:text-start">
							{formatToIDR(650_000)}
						</span>
					</div>
					<div className="flex flex-col gap-2">
						<div className="flex items-center font-medium gap-1">
							<CheckCircle2Icon size={18} fill="#398270" stroke="#FFF" />
							<span>100 Monthly Creator Analytics Credit</span>
						</div>
						<div className="flex items-center font-medium gap-1">
							<CheckCircle2Icon size={18} fill="#398270" stroke="#FFF" />
							<span>500 Monthly Engagement Rate Calculator</span>
						</div>
					</div>
					<PaymentComponent payload={{ planID: 1 }}>
						{(isPending: boolean): JSX.Element => (
							<Button
								size="sm"
								variant="primary"
								disabled={isPending}
								startIcon={
									generatePaymentIsPending && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								Subscribe
							</Button>
						)}
					</PaymentComponent>
				</div>
			</div>
		</div>
	);
};

export default NoSubscription;
