import {
	updateAccount,
	changePassword,
	updateCompany,
} from "@/services/account-services";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

// Define the expected response types from the mutation functions
type UpdateAccountResponse = Promise<ReturnType<typeof updateAccount>>;
type ChangePasswordResponse = Promise<ReturnType<typeof changePassword>>;
type UpdateCompanyResponse = Promise<ReturnType<typeof updateCompany>>;

export const useAccount = (): UseMutationResult<
	Awaited<UpdateAccountResponse>,
	unknown,
	Parameters<typeof updateAccount>[0],
	unknown
> => {
	return useMutation({ mutationFn: updateAccount });
};

export const useChangePassword = (): UseMutationResult<
	Awaited<ChangePasswordResponse>,
	unknown,
	Parameters<typeof changePassword>[0],
	unknown
> => {
	return useMutation({ mutationFn: changePassword });
};

export const useCompany = (): UseMutationResult<
	Awaited<UpdateCompanyResponse>,
	unknown,
	Parameters<typeof updateCompany>[0],
	unknown
> => {
	return useMutation({ mutationFn: updateCompany });
};
