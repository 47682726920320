import Button from "@/components/ui/button";
import { getSubscriptionInfo } from "@/services/subscription-services";
import tokenServices from "@/services/token-services";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { CircleAlert, LoaderCircleIcon } from "lucide-react";
import type React from "react";
import { useState } from "react";
import { Header, Sidebar } from "..";
import MenuBar from "../Menubar";
import Footer from "../Footer";

interface ILayout {
	children: React.ReactNode;
	withSidebar?: boolean;
	isPending?: boolean;
	refreshKey?: number | null;
	isAnalyze?: boolean | null;
	showFooter?: boolean | null;
}

const Layout: React.FC<ILayout> = ({
	children,
	withSidebar = true,
	isPending = false,
	refreshKey = null,
	isAnalyze = false,
	showFooter = true,
}: ILayout): React.ReactElement => {
	const navigate = useNavigate();
	const [isExpired, setIsExpired] = useState<boolean>(false);
	const user = tokenServices.getUser();
	const isLoggedIn = user && user.email ? true : false;

	useQuery({
		queryKey: ["get-subscription-info"],
		queryFn: async () => {
			const resp = await getSubscriptionInfo();
			if (resp.status === "INACTIVE") {
				setIsExpired(true);
			}
			return resp;
		},
		enabled: isLoggedIn,
	});

	const handleResubscribe = (): void => {
		void navigate({ to: "/settings/my-subscription" }).catch((error) => {
			console.error("Navigation error:", error);
		});
	};

	return (
		<div className="flex flex-col min-h-screen">
			<Header refreshKey={refreshKey} />
			{/* InfoBar */}
			{isExpired && (
				<div className="flex justify-center w-full py-2 px-2 bg-orange-300 sticky top-0">
					<div className="flex justify-between max-w-screen-2xl w-full">
						<div className="flex items-center">
							<CircleAlert size={20} color="#FAA61A" />
							<div className="flex items-center text-grey-600 gap-1 ml-2">
								<span className="font-semibold text-xs sm:text-base">
									Monthly Subscription Plan has expired
								</span>
								<span className="hidden sm:block ">
									Please resubscribe in order to use Analytics Credit.
								</span>
							</div>
						</div>

						<div>
							<Button
								variant="primary"
								size="xs"
								className="rounded-xl"
								onClick={handleResubscribe}
							>
								Re-subscribe
							</Button>
						</div>
					</div>
				</div>
			)}
			<div className="flex flex-grow w-full">
				<main className="flex flex-col flex-grow w-full items-center">
					{/* Loader */}
					{isPending && (
						<div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-white border opacity-75 z-50">
							<div className="flex justify-center items-center">
								<LoaderCircleIcon className="animate-spin" size={50} />
								{isAnalyze && <span className="mx-4">Analyzing...</span>}
							</div>
						</div>
					)}
					{withSidebar ? (
						<div className="flex flex-col flex-grow lg:flex-row w-full">
							<Sidebar />
							<MenuBar />
							<div className="p-4 bg-grey-100 flex-grow flex flex-col sm:py-5 sm:px-10 sm:gap-5 overflow-auto">
								{children}
								<div className="flex flex-grow flex-col justify-end">
									<Footer isSticky={false}></Footer>
								</div>
							</div>
						</div>
					) : (
						<>{children}</>
					)}
				</main>
			</div>
			{showFooter && !withSidebar && <Footer isSticky={false} />}
		</div>
	);
};

export default Layout;
