import type React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CircleXIcon, LoaderCircleIcon, LockKeyholeIcon } from "lucide-react";
import Button from "./button";

interface IModal {
	children: React.ReactNode;
	isOpen: boolean;
	title?: string;
	description?: string;
	onClose: () => void;
	onSubmit?: () => void;
	isLoading?: boolean;
	withContent?: boolean;
	submitText?: string;
	icon?: JSX.Element;
	withTitleIcon?: boolean;
	hasCancelButton?: boolean;
	submitComponent?: JSX.Element;
	hasCloseIcon?: boolean;
}
const Modal: React.FC<IModal> = ({
	children,
	title,
	description,
	isOpen,
	onClose,
	onSubmit,
	isLoading = false,
	withContent = true,
	submitText = "Continue",
	icon = <LockKeyholeIcon />,
	hasCancelButton = true,
	withTitleIcon = true,
	submitComponent,
	hasCloseIcon = true,
}: IModal) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{/* The backdrop, rendered as a fixed sibling to the panel container */}
					<div
						className="fixed inset-0 bg-grey-800/30 z-10"
						aria-hidden="true"
					/>
				</Transition.Child>

				{/* Full-screen container to center the panel */}
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-20 bg-grey-500/50">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{/* The actual dialog panel  */}
						<Dialog.Panel className="sm:mx-auto w-screen sm:max-w-[440px]">
							<div className="flex flex-col justify-center items-center w-full bg-white rounded">
								{/* Top-Bar */}
								<div className="flex w-full justify-end p-3">
									{hasCloseIcon && (
										<CircleXIcon
											fill="#BFBFBF"
											stroke="#fff"
											className="cursor-pointer hover:fill-red-500"
											onClick={onClose}
										/>
									)}
								</div>

								{/* Content */}
								{withContent ? (
									<div className="flex flex-col justify-center items-center w-full gap-4 p-5 pt-0">
										{withTitleIcon && (
											<div className="p-2 border-2 text-grey-400 rounded">
												{icon}
											</div>
										)}

										<div className="flex flex-col justify-center items-center">
											<Dialog.Title className="font-medium text-lg text-grey-800">
												{title}
											</Dialog.Title>
											<Dialog.Description className="text-sm my-2 text-grey-400">
												{description}
											</Dialog.Description>
										</div>

										{children}

										<div className="flex flex-col w-full gap-1">
											{!submitComponent && (
												<Button
													size="md"
													variant="primary"
													onClick={onSubmit}
													disabled={isLoading}
													startIcon={
														isLoading && (
															<LoaderCircleIcon className="animate-spin" />
														)
													}
												>
													{submitText}
												</Button>
											)}
											{submitComponent}
											{hasCancelButton && (
												<Button
													type="button"
													size="md"
													variant="outline"
													onClick={(error) => {
														error.preventDefault();
														onClose();
													}}
												>
													Cancel
												</Button>
											)}
										</div>
									</div>
								) : (
									<>{children}</>
								)}
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
