import { formatToIDR, handleDownload } from "@/common/helper";
import TableWithPagination from "@/components/charts/table";
import { Layout } from "@/components/layout";
import { useGeneratePayment } from "@/hooks/payment-hooks";
import UpgradeSubscription from "@/pages/settings/my-subscription/upgrade";
import type { Invoice } from "@/services/payment-services";
import { getInvoice } from "@/services/payment-services";
import { getSubscriptionInfo } from "@/services/subscription-services";
import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { DownloadIcon, LoaderCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SubscriptionDetails from "./detail";
import NoSubscription from "./no-subscription";

const columnHelper = createColumnHelper<Invoice>();

const columns: Array<ColumnDef<Invoice, string>> = [
	columnHelper.accessor("createdAt", {
		cell: (info) => dayjs(info.getValue()).format("MMMM DD, YYYY"),
		header: () => <span>Date</span>,
	}),

	columnHelper.accessor("orderDetails", {
		cell: (info) => {
			const orderDetails = info.getValue();
			const subscriptionPlanName = orderDetails?.[0]?.subscriptionPlan?.name;
			const addOnName = orderDetails?.[0]?.addOnPlan?.name;

			let description = "";
			if (addOnName === "ADDON") {
				description += "Add On Analytics Credit";
			} else if (subscriptionPlanName === "BASIC") {
				description += "Monthly Subscription";
			}

			return (
				<div className="whitespace-nowrap lg:whitespace-pre-line h-8 flex items-center">
					{description}
				</div>
			);
		},
		header: () => <span>Description</span>,
	}),
	columnHelper.accessor("amountDue", {
		cell: (info) => formatToIDR(info.getValue()),
		header: () => <span className=" items-end self-end">Amount</span>,
	}),

	columnHelper.accessor("id", {
		cell: (info) => (
			<div
				onClick={(): void => {
					handleDownload(info.getValue())
						.then(() => {
							toast.success("Invoice Downloaded");
						})
						.catch((error) => {
							toast.error("Failed to download invoice");
							console.error(error);
						});
				}}
				className="flex items-center gap-2 text-orange-700 font-normal cursor-pointer"
			>
				<DownloadIcon size={16} />
				<span>Download</span>
			</div>
		),
		header: () => <span>Invoice</span>,
	}),
] as Array<ColumnDef<Invoice, string>>;

const MySubscription: React.FC = () => {
	const [isSubscribe, setIsSubscribe] = useState<boolean>(false);
	const generatePayment = useGeneratePayment();

	const [isUpgradeVisible, setIsUpgradeVisible] = useState<boolean>(false);

	const { data: subscriptionInfo, isPending } = useQuery({
		queryKey: ["get-subscription-info"],
		queryFn: async () => {
			const resp = await getSubscriptionInfo();
			if (resp.status !== "ACTIVE") return null;
			return resp;
		},
	});

	const { data: invoiceDatas, isPending: invoiceDatasIsPending } = useQuery({
		queryKey: ["get-invoice-datas"],
		queryFn: async () => {
			const resp = await getInvoice();
			return resp;
		},
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		const scriptTag = document.createElement("script");
		scriptTag.src = import.meta.env["VITE_SB_MIDTRANS_URL"] as string;
		scriptTag.dataset["clientKey"] = import.meta.env[
			"VITE_SB_MIDTRANS_KEY"
		] as string;

		document.body.append(scriptTag);

		return () => {
			scriptTag.remove();
		};
	}, []);
	useEffect(() => {
		if (
			subscriptionInfo?.status === "ACTIVE" &&
			subscriptionInfo.subscriptionPlan.name !== "FREE_TRIAL"
		) {
			setIsSubscribe(true);
		} else {
			setIsSubscribe(false);
		}
	}, [subscriptionInfo]);

	return (
		<Layout isPending={isPending}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<span className="text-3xl text-grey-800 font-semibold">
						My Subscription
					</span>
					<span className=" text-grey-500">
						See information regarding your plan.
					</span>
				</div>

				<hr />
			</div>

			<div className="flex flex-col xl:flex-row gap-2 sm:gap-6">
				{isSubscribe && subscriptionInfo ? (
					<SubscriptionDetails
						subscriptionInfo={subscriptionInfo}
						setIsUpgradeVisible={setIsUpgradeVisible}
					/>
				) : (
					<NoSubscription
						generatePaymentIsPending={generatePayment.isPending}
					/>
				)}

				{subscriptionInfo && isUpgradeVisible && (
					<UpgradeSubscription
						subscriptionInfo={subscriptionInfo}
						cancelUpgrade={() => {
							setIsUpgradeVisible(false);
						}}
						generatePaymentIsPending={generatePayment.isPending}
					></UpgradeSubscription>
				)}
			</div>
			{/* Transaction History */}
			<div className="flex flex-col gap-4 mt-4">
				<div className="flex flex-col gap-1">
					<span className="text-3xl text-grey-800 font-semibold">
						Transaction History
					</span>
					<span className=" text-grey-500">
						See information regarding your payment history.
					</span>
				</div>

				<hr />
			</div>
			{invoiceDatasIsPending && <LoaderCircleIcon className="animate-spin" />}
			{invoiceDatas && !invoiceDatasIsPending && (
				<TableWithPagination data={invoiceDatas} columns={columns} />
			)}
		</Layout>
	);
};

export default MySubscription;
