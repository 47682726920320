type Token = {
	token: string;
	expires: string;
};

type UserPlan = {
	startDate: string;
	endDate: string;
	status: string;
	analyticsLimit: number;
	analyticsUsage: number;
};

type User = {
	id: number;
	email: string;
	name: string;
	role: string;
	isEmailVerified: boolean;
	createdAt: string;
	updatedAt: string;
	companyId: number;
	latestPlan?: UserPlan;
};

type UserTokens = {
	refresh: Token;
	access: Token;
};

type State = {
	user: User;
	tokens: UserTokens;
};
type UserState = {
	state: State;
};

class TokenService {
	public getLocalRefreshToken(): string | null {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		return userState?.state?.tokens?.refresh?.token ?? null;
	}

	public getLocalAccessToken(): string | null {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		return userState?.state?.tokens?.access?.token ?? null;
	}

	public getLocalVerifToken(): string | boolean {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		const isEmailVerified = userState?.state?.user?.isEmailVerified ?? false;
		if (isEmailVerified) {
			return userState?.state?.tokens?.access?.token ?? false;
		}
		return false;
	}

	public getUserEmail(): string | null {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		return userState?.state?.user?.email ?? null;
	}

	public getUser(): User | null {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		return userState?.state?.user ?? null;
	}

	public getPlanInfo(): UserPlan | null {
		const userState = JSON.parse(
			localStorage.getItem("user") ?? "{}"
		) as UserState;
		return userState?.state?.user?.latestPlan ?? null;
	}

	public removeUser(): void {
		localStorage.removeItem("user");
	}
}

export default new TokenService();
