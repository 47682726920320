import AnalyticsLogo from "@/assets/images/analytics-main-logo.png";
import type { FunctionComponent } from "@/common/types";
import { AuthLayout } from "@/components/layout";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useLogin } from "@/hooks/auth-hooks";
import { useAuthStore } from "@/store/auth-stores";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { EyeIcon, EyeOffIcon, LoaderCircleIcon } from "lucide-react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

type LoginType = {
	email: string;
	password: string;
};

export const loginSchema = yup.object({
	email: yup.string().required(),
	password: yup.string().required(),
});

function Login(): FunctionComponent {
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const login = useLogin();

	const persistUser = useAuthStore((state) => state.persistUser);

	const methods = useForm({
		resolver: yupResolver(loginSchema),
		mode: "onChange",
	});

	const onSubmit = (data: LoginType): void => {
		login.mutate(data, {
			onSuccess: (data) => {
				if (data.user.isEmailVerified) {
					persistUser(data);
					toast.success("login success!");
					navigate({ to: "/discover" }).catch((error) => {
						console.error(error);
					});
				} else {
					persistUser(data);
					toast.error("email is not verified!");
					navigate({ to: "/email-verification" }).catch((error) => {
						console.error(error);
					});
				}
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message);
				} else {
					console.error("unexpected error:", error);
				}
			},
		});
	};

	return (
		<AuthLayout>
			<div className="flex flex-row flex-grow w-full justify-center items-center h-full">
				<div className="flex flex-col w-screen md:w-[500px] justify-center items-center px-8 xl:px-0">
					<div className="flex self-center sm:self-end pt-10 xl:pt-0">
						<img
							src={AnalyticsLogo}
							alt="icon"
							className="w-[200px] cursor-pointer"
							onClick={(): void => {
								navigate({ to: "/" }).catch((error) => {
									console.error(error);
								});
							}}
						/>
					</div>

					<span className="text-3xl text-grey-500 font-bold self-start my-6">
						Log in
					</span>

					<FormProvider {...methods}>
						<div className="flex flex-col gap-4 w-full">
							<InputForm
								name="email"
								label="Email"
								type="email"
								placeholder="Jane@company.com"
							/>
							<InputForm
								name="password"
								label="Password"
								type={showPassword ? "text" : "password"}
								placeholder="Password"
								className="appearance-none"
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										methods
											.handleSubmit(onSubmit)()
											.catch((error) => {
												console.error(error);
											});
									}
								}}
								suffixIcon={
									showPassword ? (
										<EyeOffIcon
											className="absolute right-2 top-2 cursor-pointer"
											size={20}
											color="#808080"
											onClick={() => {
												setShowPassword(false);
											}}
										/>
									) : (
										<EyeIcon
											className="absolute right-2 top-2 cursor-pointer"
											size={20}
											color="#808080"
											onClick={() => {
												setShowPassword(true);
											}}
										/>
									)
								}
							/>
							<div className="flex justify-end mb-6">
								<Link
									to="/forgot-password"
									className="text-right text-sm text-orange-500 hover:text-orange-500/80 font-semibold cursor-default"
								>
									<span className="cursor-pointer">Forgot Password</span>
								</Link>
							</div>
						</div>

						<Button
							variant="primary"
							size="md"
							onClick={(): void => {
								methods
									.handleSubmit(onSubmit)()
									.catch((error) => {
										console.error(error);
									});
							}}
							disabled={login.isPending}
							startIcon={
								login.isPending && <LoaderCircleIcon className="animate-spin" />
							}
						>
							Continue
						</Button>
					</FormProvider>

					<p className="mt-6  font-normal text-grey-500 self-start">
						Don't have an account?{" "}
						<Link
							to="/register"
							className="font-semibold text-orange-500 hover:text-orange-500/80"
						>
							Register
						</Link>
					</p>
				</div>
			</div>
		</AuthLayout>
	);
}

export default Login;
