import type { FunctionComponent } from "@/common/types";
import Button from "@/components/ui/button";
import FrameImage from "@/assets/images/frame.svg";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Layout } from "@/components/layout";

interface IPopupFirstLogin {
	isOpen: boolean;
	onClose: (isOpen: boolean) => void;
}

const PopupFirstLogin = ({
	isOpen,
	onClose,
}: IPopupFirstLogin): JSX.Element => {
	return (
		<Transition show={isOpen} appear as={Fragment}>
			<Dialog onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{/* The backdrop, rendered as a fixed sibling to the panel container */}
					<div className="fixed inset-0 bg-grey-800/30" aria-hidden="true" />
				</Transition.Child>

				{/* Full-screen container to center the panel */}
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-grey-500/50">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{/* The actual dialog panel  */}
						<Dialog.Panel className="mx-auto rounded bg-white">
							<div className="flex max-w-3xl">
								<div className="flex justify-center items-center w-1/2 bg-grey-100 rounded-l">
									<div className="w-[200px] h-[200px]">
										<img src={FrameImage} alt="frame" />
									</div>
								</div>

								<div className="flex flex-col w-1/2 py-10 px-8">
									<div className="flex flex-col gap-5 pb-10">
										<h1 className="text-3xl font-semibold">
											Welcome to Creator Analytics!
										</h1>
										<p className="text-grey-300">
											Welcome aboard! With Creator Analytics, you can dive deep
											into influencer statistics to supercharge your business
											growth.
										</p>
									</div>

									<Button
										variant="primary"
										size="md"
										onClick={() => {
											onClose(false);
										}}
									>
										Get Started
									</Button>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

const Dashboard = (): FunctionComponent => {
	const [modalFirst, setModalFirst] = useState(true);

	return (
		<Layout withSidebar={false}>
			<p className="text-white text-6xl">Dashboard</p>

			{/* Popup First Time Login */}
			<PopupFirstLogin
				isOpen={modalFirst}
				onClose={() => {
					setModalFirst(!modalFirst);
				}}
			/>
		</Layout>
	);
};
export default Dashboard;
