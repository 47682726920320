import { AuthLayout } from "@/components/layout";
import Alert from "@/components/ui/alert";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useForgotPassword } from "@/hooks/auth-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { ArrowLeftIcon, LoaderCircleIcon } from "lucide-react";
import { Fragment, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

export const forgotPasswordSchema = yup.object({
	email: yup.string().required().email(),
});

interface AlertState {
	type: "success" | "error" | "" | undefined;
	message: string;
}

interface ForgotPasswordFormData {
	email: string;
}

const ForgotPassword = (): JSX.Element => {
	const [alert, setAlert] = useState<AlertState>({ type: "", message: "" });

	const forgotPassword = useForgotPassword();

	const methods = useForm<ForgotPasswordFormData>({
		resolver: yupResolver(forgotPasswordSchema),
	});

	const onSubmit: SubmitHandler<ForgotPasswordFormData> = (data) => {
		forgotPassword.mutate(data, {
			onSuccess: () => {
				setAlert({
					type: "success",
					message: "Please check your new password in email",
				});

				setTimeout(() => {
					setAlert({ type: "", message: "" });
				}, 4000);
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					setAlert({
						type: "error",
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
						message: error.response?.data.message ?? "Unknown error occurred",
					});

					setTimeout(() => {
						setAlert({ type: "", message: "" });
					}, 4000);
				} else {
					console.error("Unexpected error:", error);
				}
			},
		});
	};

	const renderAlert = (): JSX.Element => {
		return (
			<Fragment>
				{alert.type === "success" ? (
					<Alert
						title="Email has been sent"
						description={alert.message}
						variant={alert.type}
					/>
				) : alert.type === "error" ? (
					<Alert
						title="Error"
						description={alert.message}
						variant={alert.type}
					/>
				) : (
					<></>
				)}
			</Fragment>
		);
	};

	return (
		<AuthLayout>
			<div className="flex flex-row flex-grow w-full justify-center items-center h-full">
				<div className="flex flex-col w-full h-full max-w-screen-lg justify-center gap-4 px-8">
					<Link
						to="/login"
						className="flex items-center gap-2 text-grey-400 hover:text-orange-500/80 w-fit"
					>
						<ArrowLeftIcon />
						<span className="font-semibold">Back</span>
					</Link>

					{/* Alert */}
					{renderAlert()}

					<div className="flex flex-col gap-2">
						<span className="text-3xl font-bold text-grey-800">
							Enter Registered Email
						</span>
						<span className="text-grey-400  mb-4">
							We will send a new password to your email.
						</span>
					</div>

					<FormProvider {...methods}>
						<div className="flex flex-col gap-4 self-start w-full">
							<InputForm name="email" placeholder="Jane@company.com" />
							<Button
								size="md"
								variant="primary"
								onClick={(): void => {
									methods
										.handleSubmit(onSubmit)()
										.catch((error) => {
											console.error(error);
										});
								}}
								disabled={forgotPassword.isPending}
								startIcon={
									forgotPassword.isPending && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								Send
							</Button>
						</div>
					</FormProvider>
				</div>
			</div>
		</AuthLayout>
	);
};

export default ForgotPassword;
