import {
	hasAnalyticCredit,
	hasEmptyAnalyticCredit,
	hasERCredit,
	isFirstTimeUsingERCalc,
	openInNewTab,
} from "@/common/helper";
import type { FunctionComponent } from "@/common/types";
import SearchBar from "@/components/forms/search-bar";
import { Layout } from "@/components/layout";
import PopupFirstLogin from "@/components/modal/first-time-login";
import { customToast } from "@/components/ui/custom-toast";
import Modal from "@/components/ui/modal";
import {
	useAnalyzeInstagram,
	useAnalyzeInstagramBasic,
	useAnalyzeTikTok,
	useAnalyzeTikTokBasic,
} from "@/hooks/social-media-hooks";
import type { QueueAnalyzeResponse } from "@/services/master-services";
import {
	getListRecentAnalytics,
	getListRequestAnalytics,
} from "@/services/master-services";
import tokenServices from "@/services/token-services";
import { useCreditStore } from "@/store/credit-stores";
import type { AnalyticsSource, SocialMediaPlatform } from "@/types/master";
import type { MutateOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { AxiosError } from "axios";
import { InfoIcon } from "lucide-react";
import type { UUID } from "node:crypto";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import FeaturedCreators from "./featured-creator";
import RecentAnalysis from "./recent-analysis";
import RequestAnalysis from "./request-analysis";

interface ErrorResponse {
	message: string;
}

const handleDetailUser = (uuid: UUID, platform: SocialMediaPlatform): void => {
	window.open(`${window.location.origin}/discover/${platform}/${uuid}`);
};
const isAxiosError = (error: unknown): error is AxiosError<ErrorResponse> => {
	return (error as AxiosError<ErrorResponse>).isAxiosError !== undefined;
};

function Discover(): FunctionComponent {
	const [modalFirst, setModalFirst] = useState(true);
	const [refreshKey, setRefreshKey] = useState(0);
	const [showLimitModal, setShowLimitModal] = useState(false);
	const [showAddonModal, setShowAddonModal] = useState(false);
	const [isSearchBarLoading, setIsSearchBarLoading] = useState(false);

	const navigate = useNavigate();
	const email = tokenServices.getUserEmail();

	const creditInfo = useCreditStore((state) => state.creditInfo);
	const analyzeInstagram = useAnalyzeInstagram();
	const analyzeTikTok = useAnalyzeTikTok();
	const analyzeInstagramBasic = useAnalyzeInstagramBasic();
	const analyzeTikTokBasic = useAnalyzeTikTokBasic();
	useEffect(() => {
		const isLoggedIn = localStorage.getItem(`user-${email}`);
		if (isLoggedIn) setModalFirst(false);
	}, [email]);

	useEffect(() => {
		if (isFirstTimeUsingERCalc(creditInfo)) {
			setShowAddonModal(true);
		}
		return () => {
			null;
		};
	}, [creditInfo]);
	const {
		data: listDataRecent,
		isPending: isListDataRecentPending,
		refetch: refetchRecent,
	} = useQuery({
		queryKey: [`get-list-recent-analytics`],
		queryFn: async () => {
			const resp = await getListRecentAnalytics({
				limit: 20,
				page: 1,
				sortType: "desc",
				sortBy: "updatedAt",
			});
			return resp;
		},
		retry: 1,
		refetchOnWindowFocus: true,
		refetchInterval: 60_000 * 3,
	});

	const {
		data: listDataRequest,
		isPending: isListDataRequestPending,
		refetch: refetchRequest,
	} = useQuery({
		queryKey: [`get-list-request-analytics`],
		queryFn: async () => {
			const resp = await getListRequestAnalytics({
				limit: 20,
				page: 1,
				sortType: "desc",
				sortBy: "updatedAt",
			});
			return resp;
		},
		retry: 1,
		refetchOnWindowFocus: true,
		refetchInterval: 60_000 * 3,
	});

	useEffect(() => {
		if (refreshKey) {
			refetchRecent().catch((error) => {
				console.error(error);
			});

			refetchRequest().catch((error) => {
				console.error(error);
			});
		}
	}, [refreshKey, refetchRecent, refetchRequest]);

	const handleSearchUserBasic = (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	): void => {
		console.log(searchQuery, selectedOption, "SEARCH ");

		if (hasERCredit(creditInfo)) {
			setIsSearchBarLoading(true);
			const mutateOpt: MutateOptions<
				QueueAnalyzeResponse,
				unknown,
				{
					username: string;
					analyticsSource: AnalyticsSource;
				},
				unknown
			> = {
				onSuccess: (data: QueueAnalyzeResponse): void => {
					setRefreshKey((previousKey) => previousKey + 1);
					if (data.status === "QUEUE_SUCCESS") {
						openInNewTab(`/discover/${selectedOption}/${data.uniqueID}`);
					} else if (data.status === "ON_PROGRESS") {
						customToast(
							{
								title: "Analysis is on progress",
								description: `The username ${searchQuery} you entered on ${selectedOption} is currently being analyzed.`,
							},
							"warning"
						);
						return;
					} else {
						if (data.error === "USERNAME INVALID") {
							customToast(
								{
									title: "Username Invalid",
									description: `Please enter only valid username on ${selectedOption}`,
								},
								"error"
							);
							return;
						} else
							customToast(
								{
									title: "Analysis is on process",
									description: `The analysis for username ${searchQuery} on ${selectedOption} is estimated to take 10 minutes. \n We will send a notification to your email once it is finished.`,
								},
								"success"
							);
						return;
					}
				},
				onError: (error: unknown): void => {
					if (isAxiosError(error)) {
						if (error.response?.status === 429) {
							setShowLimitModal(true);
						} else {
							const errorMessage: string =
								typeof error.response?.data?.message === "string"
									? error.response.data.message
									: "An error occurred";
							toast.error(errorMessage);
						}
					} else {
						console.error("Unexpected error:", error);
					}
				},
				onSettled: (): void => {
					setIsSearchBarLoading(false);
				},
			};
			if (selectedOption == "instagram")
				analyzeInstagramBasic.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
			else
				analyzeTikTokBasic.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
		} else {
			setShowLimitModal(true);
		}
	};

	const handleSearchUser = (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	): void => {
		if (hasAnalyticCredit(creditInfo) || analyticsSource === "VERIFIED") {
			setIsSearchBarLoading(true);
			const mutateOpt: MutateOptions<
				QueueAnalyzeResponse,
				unknown,
				{
					username: string;
					analyticsSource: AnalyticsSource;
				},
				unknown
			> = {
				onSuccess: (data: QueueAnalyzeResponse): void => {
					setRefreshKey((previousKey) => previousKey + 1);
					if (data.status === "QUEUE_SUCCESS") {
						openInNewTab(`/discover/${selectedOption}/${data.uniqueID}`);
					} else if (data.status === "ON_PROGRESS") {
						customToast(
							{
								title: "Analysis is on progress",
								description: `The username ${searchQuery} you entered on ${selectedOption} is currently being analyzed.`,
							},
							"warning"
						);
						return;
					} else {
						if (data.error === "USERNAME INVALID") {
							customToast(
								{
									title: "Username Invalid",
									description: `Please enter only valid username on ${selectedOption}`,
								},
								"error"
							);
							return;
						}
						customToast(
							{
								title: "Analysis is on process",
								description: `The username ${searchQuery} you entered on ${selectedOption} is currently being prepared for analysis.`,
							},
							"success"
						);
					}
				},
				onError: (error: unknown): void => {
					if (isAxiosError(error)) {
						const errorMessage: string =
							typeof error.response?.data?.message === "string"
								? error.response.data.message
								: "An error occurred";
						toast.error(errorMessage);
					} else {
						console.error("Unexpected error:", error);
					}
				},
				onSettled: () => {
					setIsSearchBarLoading(false);
				},
			};
			if (selectedOption == "instagram")
				analyzeInstagram.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
			else
				analyzeTikTok.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
		}
	};

	return (
		<Layout
			withSidebar={false}
			isPending={isListDataRecentPending || isListDataRequestPending}
			refreshKey={refreshKey}
		>
			<Modal
				isOpen={showLimitModal}
				title="No ER calculator left"
				description="You can comeback tomorrow or subscribe now"
				onClose={() => {
					setShowLimitModal(false);
				}}
				children={<></>}
				submitText="Subscribe"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					navigate({ to: "/settings/my-subscription" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
			<Modal
				isOpen={showAddonModal}
				title="No analytics credit left, please add more credit"
				onClose={() => {
					setShowAddonModal(false);
				}}
				children={<></>}
				submitText="Add Credit"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					navigate({ to: "/settings/my-subscription" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
			{/* Popup First Time Login */}
			<PopupFirstLogin
				isOpen={modalFirst}
				onClose={() => {
					setModalFirst(!modalFirst);
				}}
			/>

			<div className="flex flex-col justify-center py-10 max-w-screen-xl items-center gap-1 sm:gap-10 w-full px-2 ">
				<div className="flex flex-col justify-center items-center w-full gap-4  mb-4">
					<h1 className="text-grey-500 font-semibold text-3xl mb-4 text-center">
						Find the right creator for your campaign
					</h1>
					{/* <span className="text-grey-300 text-lg">
						Optimize your influencer marketing performance by utilizing the
						right creator for the brand
					</span> */}

					<SearchBar
						onSearchUserBasic={
							hasERCredit(creditInfo)
								? handleSearchUserBasic
								: (): void => {
										hasEmptyAnalyticCredit(creditInfo)
											? setShowAddonModal(true)
											: setShowLimitModal(true);
									}
						}
						onSearchUser={
							hasAnalyticCredit(creditInfo) ? handleSearchUser : (): void => {}
						}
						defaultOption={"instagram"}
						isLoading={isSearchBarLoading}
					/>
				</div>

				<div className="flex flex-col xl:flex-row gap-5 w-full">
					<div className="flex flex-col w-full xl:w-2/3 gap-4">
						{listDataRequest && listDataRequest.data.length > 0 && (
							<RequestAnalysis
								listData={listDataRequest}
								handleDetailUser={handleDetailUser}
							></RequestAnalysis>
						)}
						{listDataRecent && (
							<RecentAnalysis
								listData={listDataRecent}
								handleSearchUser={
									hasAnalyticCredit(creditInfo)
										? handleSearchUser
										: handleSearchUserBasic
								}
							></RecentAnalysis>
						)}
					</div>

					<div className="flex flex-col w-full xl:w-1/3 px-5 gap-2 bg-white border border-grey-100 rounded-xl shadow h-fit pt-5 pb-6">
						<FeaturedCreators handleSearchUser={handleSearchUser} />
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Discover;
