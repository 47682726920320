import { useVerifyEmail } from "@/hooks/auth-hooks";
import { Route } from "@/routes/verify-email";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";

const VerifyEmail: React.FC = () => {
	const navigate = useNavigate();
	const { token } = Route.useSearch();

	const verifyEmail = useVerifyEmail();

	useEffect(() => {
		if (token) {
			const delay = setTimeout(() => {
				verifyEmail.mutate(token, {
					onSuccess: () => {
						toast.success("Email is successfully verified");
						navigate({ to: "/login" }).catch((error) => {
							console.error(error);
						});
					},
					onError: (error: unknown) => {
						if (error instanceof AxiosError) {
							// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
							toast.error(error.response?.data.message);
						} else {
							console.error("unexpected error:", error);
						}
					},
					onSettled: () => {
						navigate({ to: "/login" }).catch((error) => {
							console.error(error);
						});
					},
				});
			}, 2000); // Delay for 2 seconds

			return () => {
				clearTimeout(delay); // Clear the timeout if component unmounts
			};
		} else {
			const delay = setTimeout(() => {
				toast.error("Token doesn't exist!");
				navigate({ to: "/" }).catch((error) => {
					console.error(error);
				});
			}, 2000);

			return () => {
				clearTimeout(delay); // Clear the timeout if component unmounts
			};
		}
	}, [token, navigate, verifyEmail]);

	return (
		<div className="flex justify-center items-center h-screen">
			<div className="rounded-full h-20 w-20 bg-orange-700 animate-ping"></div>
		</div>
	);
};

export default VerifyEmail;
