import AnalyticsIconAlt from "@/assets/icons/analytics-icon-alt.png";
import {
	CheckCircle2Icon,
	CalendarDaysIcon,
	DownloadIcon,
	PlusIcon,
} from "lucide-react";
import { formatToIDR, handleDownload } from "@/common/helper";
import Button from "@/components/ui/button";
import type { SubscriptionInfo } from "@/types/subscription";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const SubscriptionDetails: React.FC<{
	subscriptionInfo: SubscriptionInfo;
	setIsUpgradeVisible: (visible: boolean) => void;
}> = ({ subscriptionInfo, setIsUpgradeVisible }) => {
	const renderAddOn = (): JSX.Element => {
		if (subscriptionInfo?.addon && subscriptionInfo.addon.length > 0) {
			const totalPriceAddOn = subscriptionInfo.addon.reduce((sum, a) => {
				return (sum += a.quantity * a.addonPlan.price);
			}, 0);
			const totalLimitAddOn = subscriptionInfo.addon.reduce((sum, a) => {
				return (sum += a.quantity * a.addonPlan.analyticsLimit);
			}, 0);

			return (
				<div className="flex flex-col p-5 gap-4 bg-grey-100 rounded-xl">
					<div className="flex items-center justify-between text-grey-800 font-semibold">
						<span>Add-On Credit</span>
						<span>{formatToIDR(totalPriceAddOn)}</span>
					</div>
					<div className="flex items-center  text-grey-500 gap-1">
						<CheckCircle2Icon size={20} fill="#14A085" stroke="#FFF" />
						<span>{totalLimitAddOn} Add-On Credit</span>
					</div>
				</div>
			);
		}
		return <></>;
	};

	return (
		<div className="flex flex-col w-full xl:w-2/3 gap-10 p-4 sm:p-6 bg-white border border-grey-100 rounded-xl my-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col sm:flex-row items-start justify-between w-full">
					<div className="flex items-center gap-2">
						<img src={AnalyticsIconAlt} alt="icon-alt" />
						<span className="text-md text-grey-500 font-semibold">
							Monthly Analytics Credit
						</span>
					</div>
					<span className="text-md text-grey-300 font-semibold">
						{subscriptionInfo?.analyticsUsage ?? 0} of{" "}
						{subscriptionInfo?.analyticsLimit ?? 0} Credit Used
					</span>
				</div>
				<progress
					value={subscriptionInfo?.analyticsUsage ?? 0}
					max={subscriptionInfo?.analyticsLimit ?? 100}
					className="w-full h-10 [&::-webkit-progress-bar]:rounded [&::-webkit-progress-value]:rounded [&::-webkit-progress-bar]:bg-orange-200 [&::-webkit-progress-value]:bg-gradient-to-t [&::-webkit-progress-value]:from-orange-600 [&::-webkit-progress-value]:to-orange-400 [&::-moz-progress-bar]:bg-gradient-to-t [&::-moz-progress-bar]:from-orange-600 [&::-moz-progress-bar]:to-orange-400 [&::-webkit-progress-value]:transition-all [&::-webkit-progress-value]:duration-500"
				></progress>
				<span className="text-md text-grey-300">
					<span className="text-orange-700 font-semibold">
						{subscriptionInfo
							? subscriptionInfo?.analyticsLimit -
								subscriptionInfo?.analyticsUsage
							: 0}
					</span>{" "}
					Remaining Credit will expire on{" "}
					{dayjs(subscriptionInfo?.endDate).format("DD MMMM YYYY")}
				</span>
			</div>
			<div className="flex flex-col gap-5">
				<span className="text-lg text-grey-500 font-semibold">Active Plan</span>
				<div className="flex flex-col sm:p-5 gap-5 bg-white sm:border border-grey-150 rounded-xl">
					<div className="flex items-center justify-between">
						<div className="flex gap-4">
							<div className="p-2 border-2 text-grey-400 rounded flex items-center">
								<CalendarDaysIcon />
							</div>
							<div className="flex flex-col">
								<span className=" text-grey-400">Due Date</span>
								<span className="text-grey-800 font-semibold">
									{dayjs(subscriptionInfo?.endDate).format("MMMM DD, YYYY")}
								</span>
							</div>
						</div>
						<div
							className="flex items-center gap-1 text-orange-700 cursor-pointer"
							onClick={(): void => {
								handleDownload(-1)
									.then(() => {
										toast.success("Invoice Downloaded");
									})
									.catch((error) => {
										toast.error("Failed to download invoice");
										console.error(error);
									});
							}}
						>
							<DownloadIcon size={20} />
							<span>Invoice</span>
						</div>
					</div>
					<div className="flex justify-between items-center py-3 px-5 text-lg font-semibold text=grey-800 border border-orange-400 rounded-xl">
						<span>
							Total <span className="text-sm text-grey-400">(Before Tax)</span>
						</span>
						<span>
							{subscriptionInfo?.totalPrice
								? formatToIDR(subscriptionInfo?.totalPrice)
								: formatToIDR(0)}
						</span>
					</div>
				</div>
				<div className="flex flex-col p-4 gap-4 bg-grey-100 rounded-xl">
					<div className="flex items-center justify-between text-grey-800 font-semibold">
						<span>Monthly Subscription</span>
						<span>
							{formatToIDR(
								subscriptionInfo?.subscriptionPlan.pricePerMonth ?? 0
							)}
						</span>
					</div>
					<div className="flex items-center  text-grey-500 gap-1">
						<CheckCircle2Icon size={20} fill="#14A085" stroke="#FFF" />
						<span>Engagement Rate Calculator</span>
					</div>
					<div className="flex items-center  text-grey-500 gap-1">
						<CheckCircle2Icon size={20} fill="#14A085" stroke="#FFF" />
						<span>100 Monthly Creator Analytics Credit</span>
					</div>
				</div>
				{renderAddOn()}
				<div className="flex items-center justify-between">
					<span className="text-sm text-grey-300">Cancel Subscription</span>
					<Button
						variant="primary"
						size="xs"
						startIcon={<PlusIcon size={16} />}
						className="w-fit"
						onClick={() => {
							setIsUpgradeVisible(true);
						}}
					>
						Credit
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionDetails;
