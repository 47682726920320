import * as React from "react";

import { cn } from "../../common/helper";
import { Controller, useFormContext } from "react-hook-form";
import Label from "./label";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					"border-input placeholder:text-muted-foreground focus:ring focus:ring-orange-500 focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1  shadow-sm transition-colors file:border-0 file:bg-transparent file: file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
					className
				)}
				ref={ref}
				{...props}
			/>
		);
	}
);
Input.displayName = "Input";

interface IInputForm extends React.InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	label?: string;
	required?: boolean;
	suffixIcon?: React.ReactNode;
	inputClassname?: string;
}

const InputForm: React.FC<IInputForm> = ({
	name = "",
	label = "",
	required = false,
	suffixIcon,
	inputClassname = "",
	...rest
}) => {
	const methods = useFormContext();

	return (
		<Controller
			control={methods.control}
			name={name}
			defaultValue=""
			render={({ field, fieldState }) => (
				<div className="flex flex-col gap-2">
					<div className="flex items-start gap-1">
						<Label className="text-[18px] text-grey-800">{label}</Label>
						{required && <Label className=" text-red-600">*</Label>}
					</div>
					<div>
						<div className="relative">
							<Input
								{...field}
								{...rest}
								className={cn(
									fieldState.invalid &&
										"ring-1 focus:ring-red-500 ring-red-500",
									inputClassname
								)}
								{...(name == "email"
									? { autoComplete: "username" }
									: name == "password"
										? { autoComplete: "current-password" }
										: null)}
							/>
							{suffixIcon}
						</div>
						{fieldState.invalid && (
							<small className="text-sm text-red-600">
								{fieldState.error?.message}
							</small>
						)}
					</div>
				</div>
			)}
		/>
	);
};

export { Input, InputForm };
