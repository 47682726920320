import axiosInstance from "@/config/axios";
import type { IPayment } from "@/pages/pricing";
import type { PlanType } from "@/types/master";

type PaymentResponse = {
	token: string;
	redirect_url: string;
};

type ValidatePromoResponse = {
	id: number;
	code: string;
	promoAmount: number;
};

type RequestFreeTrialResponse = {
	id: number;
	status: "PENDING";
};

export const generatePayment = async (
	payload: IPayment
): Promise<PaymentResponse> => {
	const { data } = await axiosInstance.post<PaymentResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/payment/generate`,
		payload
	);

	return data;
};

export const validatePromo = async (parameters: {
	code: string;
	amount: number;
	type: PlanType;
}): Promise<ValidatePromoResponse> => {
	const { data } = await axiosInstance.get<ValidatePromoResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/payment/promo/validate`,
		{ params: parameters }
	);

	return data;
};

export const requestFreeTrial = async (): Promise<RequestFreeTrialResponse> => {
	const { data } = await axiosInstance.post<RequestFreeTrialResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/subscription/request-free-trial`
	);

	return data;
};

interface SubscriptionPlan {
	name: string;
	description: string;
}

interface AddOnPlan {
	name: string;
	description: string;
}

export interface OrderDetail {
	subscriptionPlan: SubscriptionPlan;
	addOnPlan: AddOnPlan;
}
export type Invoice = {
	id: number;
	amountDue: number;
	issueDate: string;
	dueDate: string;
	paidAt: string;
	invoiceNumber: string;
	status: "PAID" | "UNPAID";
	createdAt: string;
	updatedAt: string;
	orderId: number;
	orderDetails: Array<OrderDetail>;
};

export const getInvoice = async (): Promise<Array<Invoice>> => {
	const { data } = await axiosInstance.get<Array<Invoice>>(
		`${import.meta.env["VITE_BASE_URL_API"]}/payment/invoices`
	);

	return data;
};

type DownloadInvoiceResponse = Blob;
export const downloadInvoice = async (
	invoiceID: number
): Promise<DownloadInvoiceResponse> => {
	const { data } = await axiosInstance.get<DownloadInvoiceResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/payment/invoice/download`,
		{
			params: {
				invoiceID,
			},
			responseType: "blob",
		}
	);

	return data;
};
