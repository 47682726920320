import { debounce } from "@/common/helper";
import FloatingButton from "@/components/analytics/other/floating-button";
import SearchBar from "@/components/forms/search-bar";
import { Layout } from "@/components/layout";
import Modal from "@/components/ui/modal";
import InstagramProfile from "@/pages/analytics/instagram-profile";
import TikTokProfile from "@/pages/analytics/tiktok-profile";
import { Route } from "@/routes/public.search";
import { postSocialMediaPublic } from "@/services/master-services";
import type { InstagramPublic } from "@/types/instagram";
import type { SocialMediaPlatform } from "@/types/master";
import type { TikTokPublic } from "@/types/tiktok";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { AxiosError } from "axios";
import { InfoIcon } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import {
	dummyProfileDataIG,
	dummyProfileDataTikTok,
} from "./dummy-profile-data";

export const pieData = {
	labels: ["Male", "Female"],
	datasets: [
		{
			data: [62.87, 37.13],
			backgroundColor: ["#E68C24", "#14A085"],
			borderColor: ["#E68C24", "#14A085"],
			borderWidth: 1,
		},
	],
};

type ProfileResponse = InstagramPublic | TikTokPublic;

function isTikTokPublic(response: ProfileResponse): response is TikTokPublic {
	return (response as TikTokPublic).tiktokId !== undefined;
}

function isInstagramPublic(
	response: ProfileResponse
): response is InstagramPublic {
	return (response as InstagramPublic).instagramId !== undefined;
}

const PublicSearch: React.FC = () => {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [platformQuery, setPlatformQuery] =
		useState<SocialMediaPlatform>("instagram");
	const [overlay, setOverlay] = useState<boolean>(true);
	const [showLimitModal, setShowLimitModal] = useState(false);
	const [usedProfileData, setUsedProfileData] = useState<
		InstagramPublic | TikTokPublic | undefined
	>();
	const [isDummy, setIsDummy] = useState(false);

	const { username, platform } = Route.useSearch();
	const navigate = useNavigate();

	const debouncedSearch = debounce(
		(query: string, platform: SocialMediaPlatform) => {
			setSearchQuery(query);
			setPlatformQuery(platform);
		},
		500
	);

	const debouncedUpdateUrl = debounce(
		(searchQuery: string, platformQuery: SocialMediaPlatform) => {
			const newUrl = new URL(window.location.href);
			newUrl.searchParams.set("username", searchQuery);
			newUrl.searchParams.set("platform", platformQuery);
			window.history.replaceState({ path: newUrl.href }, "", newUrl.href);
		},
		500
	);

	const { data: profileData, isPending } = useQuery({
		queryKey: [`get-${platformQuery}-public`, searchQuery],
		queryFn: () => {
			return postSocialMediaPublic({
				username: searchQuery,
				platform: platformQuery,
			}).catch((error: AxiosError) => {
				if (error.response?.status === 429) {
					setShowLimitModal(true);
					setUsedProfileData(
						platformQuery == "instagram"
							? dummyProfileDataIG
							: dummyProfileDataTikTok
					);
					setIsDummy(true);
				}
				throw error;
			});
		},
		enabled: !!searchQuery,
		retry: 1,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (profileData) {
			setUsedProfileData(profileData);
		}
	}, [profileData]);

	const handleSearchUser = (
		searchQuery: string,
		platformQuery: SocialMediaPlatform
	): void => {
		debouncedSearch(searchQuery, platformQuery);
		debouncedUpdateUrl(searchQuery, platformQuery);
	};

	useEffect(() => {
		if (username) {
			setSearchQuery(username);
		}
	}, [username]);

	useEffect(() => {
		if (platform) {
			setPlatformQuery(platform);
		}
	}, [platform]);

	return (
		<Layout withSidebar={false} isPending={isPending} isAnalyze={true}>
			<Modal
				isOpen={showLimitModal}
				title="You've reached your daily search limit"
				description="Please register to get more analytics credit."
				onClose={() => {
					setShowLimitModal(false);
				}}
				children={<></>}
				submitText="Register"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					navigate({ to: "/register" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
			<div className="flex flex-col py-10 pb-24 sm:pb-10 w-full sm:w-screen-xl max-w-screen-2xl px-2">
				<SearchBar
					onSearchUserBasic={handleSearchUser}
					defaultValue={searchQuery}
					defaultOption={platformQuery}
				/>
				{/* Profile */}
				{usedProfileData && isInstagramPublic(usedProfileData) && (
					<InstagramProfile
						profileData={usedProfileData}
						overlay={overlay}
						analyzeInfluencer={() => {
							navigate({ to: "/register" }).catch((error) => {
								console.error(error);
							});
						}}
						isDummy={isDummy}
					/>
				)}
				{usedProfileData && isTikTokPublic(usedProfileData) && (
					<TikTokProfile
						profileData={usedProfileData}
						overlay={overlay}
						analyzeInfluencer={() => {
							navigate({ to: "/register" }).catch((error) => {
								console.error(error);
							});
						}}
						isDummy={isDummy}
					/>
				)}

				<FloatingButton
					overlay={overlay}
					setOverlay={setOverlay}
				></FloatingButton>
			</div>
		</Layout>
	);
};

export default PublicSearch;
