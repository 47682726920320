import { Layout } from "@/components/layout";
import CheckPasswordModal from "@/components/modal/check-password";
import Button from "@/components/ui/button";
import { getAccount } from "@/services/account-services";
import { getCities, getProvince } from "@/services/master-services";
import { useQuery } from "@tanstack/react-query";
import { PencilIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

type TProvince = {
	name: string;
	id: number;
};

type TCity = {
	name: string;
	regionType: number;
	id: number;
	provinceId: number;
};

type TAddressInfo = {
	city: string;
	province: string;
};

const Payment: React.FC = () => {
	const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
	const [addressInfo, setAddressInfo] = useState<TAddressInfo>({
		city: "",
		province: "",
	});

	const { data, isPending } = useQuery({
		queryKey: ["get-account"],
		queryFn: async () => {
			const resp = await getAccount();
			return resp;
		},
	});

	const { data: provinces } = useQuery({
		queryKey: ["get-province"],
		queryFn: async () => {
			const resp = await getProvince();
			return resp;
		},
	});

	const { data: cities } = useQuery({
		queryKey: ["get-cities"],
		queryFn: async () => {
			const resp = await getCities();
			return resp;
		},
	});

	const handleAddress = useCallback(() => {
		const selectedCity = cities?.find(
			(element: TCity) => element.id === data?.company?.cityId
		);

		const selectedProvince = provinces?.find(
			(element: TProvince) => element.id === selectedCity?.provinceId
		);

		setAddressInfo({
			province: selectedProvince ? selectedProvince.name : "",
			city: selectedCity ? selectedCity.name : "",
		});
	}, [cities, provinces]);

	useEffect(() => {
		handleAddress();
	}, [handleAddress, cities, provinces]);

	return (
		<Layout isPending={isPending}>
			<div className="flex flex-col gap-4">
				<span className="text-lg font-semibold">Payment Information</span>
				<hr />
			</div>

			<div className="flex flex-col gap-4 mt-2">
				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Business Name
					</span>
					<span className="font-normal flex-1">
						{data?.company?.name ?? "-"}
					</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold  flex-1 max-w-[250px]">
						Type
					</span>
					<span className="font-normal flex-1">
						{data?.company?.industry === 1
							? "Brand"
							: data?.company?.industry === 2
								? "Agency"
								: "Others"}
					</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Website
					</span>
					<span className="font-normal flex-1">
						{data?.company?.website ?? "-"}
					</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Province
					</span>
					<span className="font-normal flex-1">
						{addressInfo?.province ?? "-"}
					</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						City
					</span>
					<span className="font-normal flex-1">{addressInfo?.city ?? "-"}</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Address
					</span>
					<span className="font-normal flex-1">
						{data?.company?.address ?? "-"}
					</span>
				</div>

				<div className="flex items-center">
					<span className="text-grey-500 font-semibold flex-1 max-w-[250px]">
						Tax ID (NPWP)
					</span>
					<span className="font-normal flex-1">
						{data?.company?.taxId ?? "-"}
					</span>
				</div>
			</div>
			<div className="mt-4 xm:mt-0 w-fit">
				<Button
					size="xs"
					variant="secondary"
					startIcon={<PencilIcon size={16} />}
					onClick={() => {
						setConfirmPasswordModal(true);
					}}
				>
					Edit
				</Button>
			</div>
			{/*
			<div className="flex flex-col gap-4">
				<span className="text-lg font-semibold">Payment Method</span>
				<hr />
			</div>

			<div className="flex items-center bg-white p-5 gap-5 w-fit border border-grey-100 rounded-xl">
				<div className="p-1 rounded-lg border border-grey-100">
					<img src={VisaIcon} alt="visa-icon" />
				</div>

				<div className="flex flex-col">
					<span className="text-grey-800 font-medium">Rizky Firdaus</span>
					<span className="text-grey-400">1234 **** **** 8652</span>
				</div>

				<div>
					<Button variant="secondary" size="xs">
						Update
					</Button>
				</div>
			</div> */}

			{/* Render Modal */}
			<CheckPasswordModal
				isOpen={confirmPasswordModal}
				onClose={() => {
					setConfirmPasswordModal(false);
				}}
				href="/settings/payment/edit-payment"
			/>
		</Layout>
	);
};

export default Payment;
