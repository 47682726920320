import type React from "react";
import { cn } from "../../common/helper";

interface LabelProps {
	children: React.ReactNode;
	className?: string;
}
const Label: React.FC<LabelProps> = ({ children, className }: LabelProps) => {
	return (
		<label className={cn("text-sm text-grey-500 font-semibold", className)}>
			{children}
		</label>
	);
};

export default Label;
