import type { ReactNode, HTMLAttributes } from "react";
import type React from "react";
import { useEffect } from "react";

// Define the type for the props, extending standard div attributes
interface NoScrollbarDivProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
}

const createNoScrollbarStyles = (): void => {
	const style = document.createElement("style");
	style.type = "text/css";
	style.innerHTML = `
    .no-scrollbar .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar .react-horizontal-scrolling-menu--scroll-container {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
  `;
	document.head.append(style);
};

const NoScrollbarDiv: React.FC<NoScrollbarDivProps> = ({
	children,
	...props
}) => {
	useEffect(() => {
		createNoScrollbarStyles();
	}, []);

	return (
		<div className="no-scrollbar" {...props}>
			{children}
		</div>
	);
};

export default NoScrollbarDiv;
