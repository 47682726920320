import type {
	AxiosInstance,
	AxiosResponse,
	AxiosError,
	InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import { refreshToken } from "./refresh-token";
import tokenServices from "@/services/token-services";

const axiosInstance: AxiosInstance = axios.create({
	baseURL: import.meta.env["VITE_BASE_URL_API"] as string,
	timeout: 500_000,
});

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const accessToken = tokenServices.getLocalAccessToken();

		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error: AxiosError) => {
		const originalRequest = error.config as InternalAxiosRequestConfig & {
			_retry?: boolean;
		};

		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const newAccessToken: string = await refreshToken();
				// Replace the expired access token and retry the original request
				originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
				return await axiosInstance(originalRequest);
			} catch (refreshError) {
				// If refreshing token fails, logout user or handle as needed
				console.error("Error refreshing token:", refreshError);
				tokenServices.removeUser();
				throw refreshError;
			}
		}
		throw error;
	}
);

export default axiosInstance;
