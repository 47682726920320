import { createFileRoute, redirect } from "@tanstack/react-router";
import Login from "../pages/auth/login";
import tokenServices from "@/services/token-services";

export const Route = createFileRoute("/login")({
	component: Login,
	beforeLoad: () => {
		if (tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/discover" });
		}
	},
});
