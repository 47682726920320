import type { LoginResponse } from "@/common/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface IAuthStore {
	user: LoginResponse["user"] | null;
	tokens: LoginResponse["tokens"] | null;
	persistUser: (data?: LoginResponse) => void;
	removeUser: () => void;
}

export const useAuthStore = create<IAuthStore>()(
	persist(
		(set) => ({
			user: null,
			tokens: null,
			persistUser: (data): void => {
				if (data) {
					set({ user: data.user, tokens: data.tokens });
				}
			},
			removeUser: (): void => {
				set({ user: null, tokens: null });
			},
		}),
		{ name: "user", storage: createJSONStorage(() => localStorage) }
	)
);
