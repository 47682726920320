import * as yup from "yup";

export const changePasswordSchema = yup.object({
	currentPassword: yup.string().required(),
	newPassword: yup
		.string()
		.required()
		.matches(
			/^(?=.*[A-Z])(?=.*\d).{8,20}$/,
			"Password doesn't match requirements"
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), undefined], "Passwords must match")
		.required(),
});

export type ChangePasswordFormData = yup.InferType<typeof changePasswordSchema>;
