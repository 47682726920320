import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import type { ChartData, ChartDataset, ChartOptions } from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface IBarChart {
	indexAxis?: "horizontal" | "vertical";
	datasets?: Array<number>;
	labels?: Array<string>;
	colors?: Array<string>;
	stacked?: boolean;
}

export const defaultColorsList = [
	"#E68C24",
	"#14A085",
	"#359FD9",
	"#FAD162",
	"#9DCFEC",
	"#E68C24",
	"#14A085",
	"#359FD9",
	"#FAD162",
	"#9DCFEC",
];

const BarChart = ({
	indexAxis = "horizontal",
	datasets,
	labels,
	colors = defaultColorsList,
	stacked = true,
}: IBarChart): React.ReactElement => {
	const constructDataset = (
		datasets: Array<number>
	): Array<ChartDataset<"bar">> => {
		if (stacked)
			return datasets.map((data, index) => ({
				label: labels && labels.length > 0 ? labels[index] : "Other",
				data: [data],
				borderSkipped: false,
				barPercentage: 1,
				borderRadius: {
					bottomLeft: index == 0 ? 4 : 0,
					topLeft: index == 0 ? 4 : 0,
					bottomRight: index == datasets.length - 1 ? 4 : 0,
					topRight: index == datasets.length - 1 ? 4 : 0,
				},
				categoryPercentage: 1,
				barThickness: 30,
				maxBarThickness: 30,
				backgroundColor: colors[index],
				stack: `Stack ${stacked ? "0" : index}`,
			}));
		return [
			{
				data: datasets,
				borderSkipped: false,
				barThickness: 40,
				maxBarThickness: 50,
				backgroundColor: "#E68C24",
				stack: "Stack 0",
			},
		];
	};
	const options: ChartOptions<"bar"> = {
		indexAxis: indexAxis === "horizontal" ? "x" : "y",
		layout: {
			padding: {
				top: 20,
			},
		},
		plugins: {
			title: {
				display: false,
				text: "Age",
			},
			legend: {
				display: stacked,
				position: "bottom",
				align: "start",
				labels: {
					generateLabels: (chart) => {
						if (chart && chart.data && chart.data.labels)
							return chart.data.labels.map((l, index) => {
								return {
									datasetIndex: index,
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									text: `${l as string} (${chart.data["datasets"][index]?.data}%)`,
									fontColor: "rgb(91, 92, 94)",
									fillStyle: colors[index],
									strokeStyle: colors[index],
									hidden: chart.getDatasetMeta(index).hidden,
									index,
								};
							});
						return [
							{
								text: "",
							},
						];
					},
					usePointStyle: true,
					pointStyle: "circle",
					padding: 10,
				},
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || "";

						if (label) {
							label += ": ";
						}
						if (context.parsed.y !== null) {
							label += context.parsed.y + "%";
						}
						return label;
					},
				},
			},
			datalabels: {
				display: !stacked,
				anchor: "end",
				align: "start",
				// clamp: true,
				offset: -20,
				formatter: function (value) {
					return value + "%";
				},
			},
		},
		responsive: true,
		maintainAspectRatio: true,

		elements: {
			bar: {
				borderRadius: {
					topLeft: 4,
					topRight: 4,
					bottomLeft: 0,
					bottomRight: 0,
				},
			},
		},
		scales: {
			x: {
				stacked: stacked,
				display: !stacked,
				max: datasets?.reduce((data, sum) => data + sum, 0),
				grid: {
					lineWidth: 0,
				},
			},
			y: {
				stacked: stacked,
				display: false,
				...(indexAxis == "vertical" && { max: 1 }),
			},
		},
	};

	const barData: ChartData<"bar"> = {
		labels: labels ?? [
			"13-17",
			"18-24",
			"25-34",
			"34-44",
			"45-54",
			"55-64",
			"65+",
		],
		datasets: datasets
			? constructDataset(datasets)
			: [
					{
						label: "Male",
						data: [20, 30, 50, 20, 50, 100, 5],
						borderSkipped: false,
						barThickness: 50,
						maxBarThickness: 50,
						backgroundColor: "#14A085",
						stack: "Stack 0",
					},
				],
	};

	return <Bar plugins={[ChartDataLabels]} options={options} data={barData} />;
};

export default BarChart;
