// components/TableWithPagination.tsx
import { Input } from "@/components/ui/input";
import type { Invoice } from "@/services/payment-services";
import type { ColumnDef } from "@tanstack/react-table";
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useState } from "react";

interface TableWithPaginationProps {
	data: Array<Invoice>;
	columns: Array<ColumnDef<Invoice, string>>;
}

const TableWithPagination: React.FC<TableWithPaginationProps> = ({
	data,
	columns,
}) => {
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		state: {
			pagination,
		},
	});

	return (
		<div className="flex flex-col gap-4 text-grey-800 ">
			<div className="overflow-x-auto max-h-screen">
				<table className="h-full w-full table-auto whitespace-nowrap table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										className="text-left font-normal text-grey-500 py-2 px-3"
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className="bg-white">
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td key={cell.id} className="font-medium py-2 px-3">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="flex w-full items-center justify-end gap-4">
				<span>
					{table.getState().pagination.pageIndex *
						table.getState().pagination.pageSize +
						1}{" "}
					-{" "}
					{Math.min(
						(table.getState().pagination.pageIndex + 1) *
							table.getState().pagination.pageSize,
						data.length
					)}{" "}
					of {data.length}
				</span>
				<div className="flex items-center gap-4">
					<div
						className="flex items-center justify-center w-9 h-9 bg-grey-100 rounded-lg cursor-pointer"
						onClick={() => {
							if (table.getCanPreviousPage()) table.previousPage();
						}}
					>
						<ChevronLeftIcon size={16} />
					</div>

					<Input
						className="w-16"
						type="number"
						value={table.getState().pagination.pageIndex + 1}
						defaultValue={table.getState().pagination.pageIndex + 1}
						max={Math.ceil(data.length / table.getState().pagination.pageSize)}
						onChange={(event_) => {
							let page = event_.target.value
								? Number(event_.target.value) - 1
								: 0;
							const maxPageIndex = Math.ceil(
								data.length / table.getState().pagination.pageSize
							);

							if (page > maxPageIndex - 1) {
								page = maxPageIndex - 1;
							} else if (page < 0) {
								page = 0;
							}

							table.setPageIndex(page);
						}}
					/>

					<div
						className="flex items-center justify-center w-9 h-9 bg-grey-100 rounded-lg cursor-pointer"
						onClick={() => {
							if (table.getCanNextPage()) table.nextPage();
						}}
					>
						<ChevronRightIcon size={16} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableWithPagination;
