import type { FreeCreators } from "@/services/master-services";
import { create } from "zustand";

interface FeaturedCreatorState {
	freeCreatorList: Array<FreeCreators>;
	setFreeCreatorList: (list: Array<FreeCreators>) => void;
}

export const useFeaturedCreatorStore = create<FeaturedCreatorState>((set) => ({
	freeCreatorList: [], // Initialize with an empty array
	setFreeCreatorList: (list: Array<FreeCreators>): void => {
		set({ freeCreatorList: list });
	},
}));
