import type * as React from "react";

interface SliderTooltipProps {
	children: React.ReactNode;
	theme: {
		color?: string;
		fontSize?: string;
		fontFamily?: string;
		whiteSpace?: string;
		arrowSize?: number;
	};
}

const SliderTooltip: React.FC<SliderTooltipProps> = ({
	children,
	theme = {},
}) => {
	const arrowSize = theme.arrowSize || 8; // Size of the arrow
	const arrowShadowSize = 16;
	const themeTooltip: React.CSSProperties = {
		color: theme.color || "grey", // Default text color
		fontSize: theme.fontSize || "14px",
		fontFamily: theme.fontFamily || "Metric",
		whiteSpace: theme.whiteSpace || "nowrap",
		position: "absolute",
		top: `calc(100% + ${arrowSize}px)`, // Position below the element with arrow height added
		left: "50%",
		transform: "translateX(-50%)",
		backgroundColor: "white", // White background
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Shadow for the tooltip
		padding: "8px 12px", // Padding for spacing around content
		borderRadius: "4px", // Rounded corners
		width: "50px", // Width of the tooltip
		zIndex: 1, // Ensure tooltip is above other elements
	};

	const arrowStyles: React.CSSProperties = {
		position: "absolute",
		width: 0,
		height: 0,
		borderLeft: `${arrowSize}px solid transparent`,
		borderRight: `${arrowSize}px solid transparent`,
		borderTop: `${arrowSize}px solid white`,
		left: "50%",
		bottom: `-55px`, // Adjust this based on arrow size
		transform: "translateX(-50%)", // Rotate arrow for downward direction
		zIndex: 1000,
		boxShadow: `
    0px ${arrowShadowSize}px ${arrowShadowSize}px -${arrowSize}px rgba(0, 0, 0, 0.2),
    0px ${arrowShadowSize}px ${arrowShadowSize}px -${arrowSize}px rgba(0, 0, 0, 0.14),
    0px ${arrowShadowSize}px ${arrowShadowSize}px -${arrowSize}px rgba(0, 0, 0, 0.12)
  `, //
	};

	return (
		<div
			style={{
				position: "relative",
				width: "100%",
				textAlign: "center",
				marginTop: -60,
			}}
		>
			<div style={arrowStyles}></div>
			<div style={themeTooltip}>{children}</div>
		</div>
	);
};

export default SliderTooltip;
