import AnalyticsLogo from "@/assets/images/analytics-main-logo.png";
import SelectForm from "@/components/forms/select";
import { AuthLayout } from "@/components/layout";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useRegister } from "@/hooks/auth-hooks";
import { useAuthStore } from "@/store/auth-stores";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { EyeIcon, EyeOffIcon, LoaderCircleIcon } from "lucide-react";
import type { FC } from "react";
import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

export const registerSchema = yup.object({
	name: yup.string().required(),
	email: yup.string().email().required(),
	phone: yup.string().required(),
	companyName: yup.string().required(),
	industry: yup.object({ label: yup.string(), value: yup.number() }).required(),
	referralSource: yup
		.object({ label: yup.string(), value: yup.number() })
		.required(),
});

const industryOption = [
	{ value: 1, label: "Brand" },
	{ value: 2, label: "Agency" },
	{ value: 3, label: "Others" },
];

const refferalOption = [
	{ value: 1, label: "Online Search (e.g Google, Bing, Yahoo)" },
	{ value: 2, label: "Social Media (e.g Instagram, Facebook, TikTok, etc.)" },
	{ value: 3, label: "Advertising" },
	{ value: 4, label: "Events/Conferences" },
	{ value: 5, label: "Blog or Publications" },
	{ value: 6, label: "Others" },
];

const Register: FC = () => {
	const [showPassword, setShowPassword] = useState(false);

	const methods = useForm({
		resolver: yupResolver(registerSchema),
	});

	const navigate = useNavigate();
	const persistUser = useAuthStore((state) => state.persistUser);

	const register = useRegister();

	const onSubmit: SubmitHandler<yup.InferType<typeof registerSchema>> = (
		data
	) => {
		register.mutate(data, {
			onSuccess: (data) => {
				persistUser({
					user: {
						companyId: data.user.companyId,
						email: data.user.email,
						id: data.user.id,
						isEmailVerified: data.user.isEmailVerified,
						name: data.user.name,
						role: data.user.role,
					},
					tokens: {
						access: {
							token: data.tokens.access.token,
							expires: new Date(data.tokens.access.expires),
						},
					},
				});
				toast.success("Registration successful");
				void navigate({ to: "/email-verification" });
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message ?? "Unknown error occurred");
				} else {
					console.error("unexpected error:", error);
				}
			},
		});
	};

	return (
		<AuthLayout>
			<div className="flex flex-row flex-grow w-full justify-center items-center h-full">
				<div className="flex flex-col w-screen md:w-[500px] px-8 xl:px-0 justify-center items-center">
					<div className="flex self-center sm:self-end pt-10 xl:pt-0">
						<img
							src={AnalyticsLogo}
							alt="icon"
							className="w-[200px] cursor-pointer"
							onClick={() => void navigate({ to: "/" })}
						/>
					</div>

					<div className="flex self-start flex-col gap-4">
						<p className="font-normal text-grey-500 text-lg">
							Already have an account?{" "}
							<Link
								to="/login"
								className="font-semibold text-orange-500 hover:text-orange-500/80"
							>
								Login
							</Link>
						</p>

						<span className="text-3xl text-grey-500 font-bold">Register</span>
					</div>

					<FormProvider {...methods}>
						<div className="flex flex-col gap-4 self-start my-4 w-full">
							<SelectForm
								label="How do you know us?"
								name="referralSource"
								options={refferalOption}
							/>

							<div className="flex flex-wrap md:flex-nowrap gap-3">
								<div className="w-full md:w-1/2">
									<InputForm
										name="name"
										label="Name"
										type="text"
										placeholder="Full Name"
									/>
								</div>
								<div className="w-full md:w-1/2">
									<InputForm
										name="email"
										label="Work Email"
										type="email"
										placeholder="Enter Work Email"
									/>
								</div>
							</div>

							<InputForm
								name="phone"
								label="Phone Number"
								type="text"
								placeholder="08123456789"
							/>

							<div className="flex flex-wrap md:flex-nowrap gap-3">
								<div className="w-full md:w-1/2">
									<InputForm
										name="companyName"
										label="Company Name"
										type="text"
										placeholder="Company Name"
									/>
								</div>
								<div className="w-full md:w-1/2">
									<SelectForm
										label="Company Type"
										name="industry"
										options={industryOption}
									/>
								</div>
							</div>

							<InputForm
								name="password"
								label="Create Password"
								type={showPassword ? "text" : "password"}
								placeholder="Password"
								className="appearance-none"
								suffixIcon={
									showPassword ? (
										<EyeOffIcon
											className="absolute right-2 top-2 cursor-pointer"
											size={20}
											color="#808080"
											onClick={() => {
												setShowPassword(false);
											}}
										/>
									) : (
										<EyeIcon
											className="absolute right-2 top-2 cursor-pointer"
											size={20}
											color="#808080"
											onClick={() => {
												setShowPassword(true);
											}}
										/>
									)
								}
							/>
						</div>

						<Button
							className="mt-2"
							variant="primary"
							size="md"
							onClick={() => {
								void methods.handleSubmit(onSubmit)();
							}}
							disabled={register.isPending}
							startIcon={
								register.isPending && (
									<LoaderCircleIcon className="animate-spin" />
								)
							}
						>
							Register
						</Button>
					</FormProvider>
				</div>
			</div>
		</AuthLayout>
	);
};

export default Register;
