import MySubscription from "@/pages/settings/my-subscription";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/settings/my-subscription")({
	component: MySubscription,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
