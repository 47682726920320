import axiosInstance from "@/config/axios";
import type { editAccountSchema } from "@/pages/settings/my-account/edit-profile";
import type { InferType } from "yup";

// Define the types for the API responses
interface Account {
	id: number;
	email: string;
	name: string;
	role: string;
	isEmailVerified: boolean;
	companyId: number;
	phone: string;
	referralSource: number;
	company: {
		id: number;
		name: string;
		industry: number;
		address: string;
		website: string;
		cityId: number;
		taxId: string;
	};
}

interface UpdateAccountResponse {
	email: string;
	name: string;
}

type ChangePasswordResponse = boolean;

interface UpdateCompanyResponse {
	name: string;
	cityId: number;
	industry: number;
	website: string;
	address: string;
	taxId: string;
}

export const getAccount = async (): Promise<Account> => {
	const { data } = await axiosInstance.get<Account>(
		`${import.meta.env["VITE_BASE_URL_API"]}/account`
	);

	return data;
};

export const updateAccount = async (
	payload: InferType<typeof editAccountSchema>
): Promise<UpdateAccountResponse> => {
	const { data } = await axiosInstance.put<UpdateAccountResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/account`,
		payload
	);

	return data;
};

export const changePassword = async (payload: {
	oldPassword: string;
	newPassword: string;
}): Promise<ChangePasswordResponse> => {
	const { data } = await axiosInstance.post<ChangePasswordResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/account/change-password`,
		payload
	);
	return data;
};

export const updateCompany = async (payload: {
	cityId: number;
	name: string;
	industry: number;
	address: string;
	website?: string;
	taxId: string;
}): Promise<UpdateCompanyResponse> => {
	const { data } = await axiosInstance.put<UpdateCompanyResponse>(
		`${import.meta.env["VITE_BASE_URL_API"]}/account/company`,
		payload
	);

	return data;
};
