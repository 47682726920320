import { Header } from "@/components/layout";

const PrivacyPolicy = (): JSX.Element => {
	return (
		<>
			<Header refreshKey={0}></Header>
			<div className="bg-gray-100 text-gray-800 py-10">
				<div className="max-w-4xl mx-auto py-10 px-6 bg-white shadow-lg rounded-lg">
					<h1 className="text-3xl font-semibold mb-6 text-center">
						Privacy Policy for Creator Analytics by Influence ID
					</h1>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
						<p>
							Welcome to Creator Analytics by Influence ID. We are committed to
							protecting your privacy and ensuring the security of your personal
							information. This Privacy Policy outlines how we collect, use,
							disclose, and safeguard your information when you use our
							platform.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							2. Information We Collect
						</h2>
						<ul className="list-disc list-inside">
							<li>
								<strong>Personal Information:</strong> When you register for an
								account, we may collect personal information such as your name,
								email address, and phone number.
							</li>
							<li>
								<strong>Usage Data:</strong> We collect information about your
								interactions with our platform, including the searches you
								perform, the creators you analyze, and the reports you generate.
							</li>
							<li>
								<strong>Cookies and Tracking Technologies:</strong> We use
								cookies and similar tracking technologies to enhance your
								experience on our platform, analyze usage patterns, and deliver
								personalized content.
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							3. How We Use Your Information
						</h2>
						<ul className="list-disc list-inside">
							<li>
								<strong>Provide and Improve Services:</strong> We use your
								information to provide, maintain, and improve our services,
								including personalizing your experience and developing new
								features.
							</li>
							<li>
								<strong>Communications:</strong> We may use your contact
								information to send you updates, newsletters, marketing
								communications, and respond to your inquiries.
							</li>
							<li>
								<strong>Analytics and Research:</strong> We use aggregated and
								anonymized data to understand usage patterns and improve our
								platform’s functionality and user experience.
							</li>
							<li>
								<strong>Compliance and Protection:</strong> We may use your
								information to comply with legal obligations, resolve disputes,
								and enforce our terms and policies.
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							4. Sharing Your Information
						</h2>
						<ul className="list-disc list-inside">
							<li>
								<strong>Service Providers:</strong> We may share your
								information with third-party service providers who perform
								services on our behalf, such as payment processing, data
								analysis, and email delivery.
							</li>
							<li>
								<strong>Business Transfers:</strong> In the event of a merger,
								acquisition, or sale of all or a portion of our assets, your
								information may be transferred as part of the transaction.
							</li>
							<li>
								<strong>Legal Requirements:</strong> We may disclose your
								information if required by law or in response to valid requests
								by public authorities.
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
						<p>
							We implement appropriate technical and organizational measures to
							protect your personal information against unauthorized access,
							loss, or misuse. However, no system can be completely secure, and
							we cannot guarantee the absolute security of your information.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
						<ul className="list-disc list-inside">
							<li>
								<strong>Access and Correction:</strong> You have the right to
								access and correct the personal information we hold about you.
							</li>
							<li>
								<strong>Deletion:</strong> You may request the deletion of your
								personal information, subject to certain legal requirements.
							</li>
							<li>
								<strong>Opt-Out:</strong> You may opt-out of receiving marketing
								communications from us at any time by following the unsubscribe
								instructions in our emails or contacting us directly.
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">7. Third-Party Links</h2>
						<p>
							Our platform may contain links to third-party websites. We are not
							responsible for the privacy practices or the content of these
							websites. We encourage you to review the privacy policies of any
							third-party sites you visit.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							8. Changes to This Privacy Policy
						</h2>
						<p>
							We may update this Privacy Policy from time to time to reflect
							changes in our practices or applicable laws. We will notify you of
							any significant changes by posting the new Privacy Policy on our
							platform and updating the effective date.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
						<p>
							If you have any questions or concerns about this Privacy Policy or
							our data practices, please contact us at:
						</p>
						<p>
							Influence ID Support Team
							<br />
							Email:{" "}
							<a
								href="mailto:support@influence.id"
								className="text-blue-500 hover:underline"
							>
								support@influence.id
							</a>
							<br />
							Phone: +62 877-9770-0999
						</p>
					</section>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
