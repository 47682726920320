import { formatToIDR } from "@/common/helper";
import { getAllPlans } from "@/services/subscription-services";
import type { SubscriptionInfo } from "@/types/subscription";
import { useQuery } from "@tanstack/react-query";
import { LoaderCircleIcon, MinusIcon, PlusIcon } from "lucide-react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import Button from "../../../components/ui/button";
import SliderTooltip from "../../../components/ui/slider-tooltip";
import dayjs from "dayjs";
import PaymentComponent from "@/components/subscription/midtrans";

interface IUpgradeSubscription {
	subscriptionInfo: SubscriptionInfo;
	cancelUpgrade: () => void;
	generatePaymentIsPending: boolean;
}
const MAX_ADDON = 1500;
const MIN_ADDON = 0;
const STEP_ADDON = 100;
const UpgradeSubscription: React.FC<IUpgradeSubscription> = ({
	subscriptionInfo,
	cancelUpgrade,
	generatePaymentIsPending,
}) => {
	const [sliderValue, setSliderValue] = useState(100);
	const { subscriptionPlan, addon, endDate, totalPrice } = subscriptionInfo;
	const [minimumValueAddon, setMinimumValueAddon] = useState(
		subscriptionPlan.analyticsLimit
	);

	useEffect(() => {
		if (addon && addon.length > 0) {
			const totalLimitAddOn = addon.reduce(
				(sum, a) => (sum += a.quantity * a.addonPlan.analyticsLimit),
				0
			);
			setMinimumValueAddon(subscriptionPlan.analyticsLimit + totalLimitAddOn);
			setSliderValue(subscriptionPlan.analyticsLimit + totalLimitAddOn);
		}
	}, [addon, subscriptionPlan.analyticsLimit]); // Only re-run the effect if `addon` changes

	const [showErrorInfo, setShowErrorInfo] = useState(false);
	const { data: planData, isPending: planDataIsPending } = useQuery({
		queryKey: ["get-plan-datas"],
		queryFn: async () => {
			const resp = await getAllPlans();
			return resp;
		},
		enabled: true,
	});

	const handleDecrease = (): void => {
		setSliderValue((previous) =>
			Math.max(previous - STEP_ADDON, minimumValueAddon)
		);
		if (sliderValue === minimumValueAddon) {
			setShowErrorInfo(true);
		} else {
			setShowErrorInfo(false);
		}
	};

	const handleIncrease = (): void => {
		setSliderValue((previous) => Math.min(previous + STEP_ADDON, MAX_ADDON));
		setShowErrorInfo(false);
	};

	const renderAddOn = (): JSX.Element => {
		if (addon && addon.length > 0) {
			const totalPriceAddOn = addon.reduce((sum, a) => {
				return (sum += a.quantity * a.addonPlan.price);
			}, 0);
			const totalLimitAddOn = addon.reduce((sum, a) => {
				return (sum += a.quantity * a.addonPlan.analyticsLimit);
			}, 0);
			return (
				<div className="flex justify-between">
					<div className="flex flex-col text-grey-500">
						<span className="">Add-On Credit</span>
						<span className="text-sm font-light">
							{totalLimitAddOn} Add-On Credit
						</span>
					</div>
					<span className="text-grey-800  font-semibold">
						{formatToIDR(totalPriceAddOn)}
					</span>
				</div>
			);
		}
		return <></>;
	};

	const getDiffAddOn = (): number => {
		return sliderValue - minimumValueAddon;
	};

	const getPriceAddOn = (): number => {
		if (
			planData &&
			planData.addonPlan &&
			planData.addonPlan.length > 0 &&
			planData.addonPlan[0]
		)
			return (
				(getDiffAddOn() / planData.addonPlan[0].analyticsLimit) *
				planData.addonPlan[0].price
			);
		return 0;
	};
	const renderUpgradeAddOn = (): JSX.Element => {
		if (sliderValue <= minimumValueAddon) return <></>;
		if (
			planData &&
			planData.addonPlan &&
			planData.addonPlan.length > 0 &&
			planData.addonPlan[0]
		) {
			return (
				<div className="flex justify-between">
					<div className="flex flex-col text-grey-500">
						<span className=" text-orange-700">+ Add-On Credit</span>
						<span className="text-sm text-orange-700 font-light">
							+ {getDiffAddOn()} Add-On Credit
						</span>
					</div>
					<span className="text-orange-700 font-semibold whitespace-nowrap">
						+ {formatToIDR(getPriceAddOn())}
					</span>
				</div>
			);
		}
		return <></>;
	};
	if (planDataIsPending)
		return (
			<div>
				<LoaderCircleIcon className="animate-spin" />
			</div>
		);
	const renderUpgradePrice = (): string => {
		if (sliderValue <= minimumValueAddon) return "";
		if (
			planData &&
			planData.addonPlan &&
			planData.addonPlan.length > 0 &&
			planData.addonPlan[0]
		) {
			const diffValue = sliderValue - minimumValueAddon;
			return `for ${formatToIDR((diffValue / planData.addonPlan[0].analyticsLimit) * planData.addonPlan[0].price)} (Before Tax)`;
		}
		return "";
	};

	return (
		<div className="flex flex-col w-full xl:w-1/3 h-fit gap-5 p-4 sm:p-6 sm:mt-4 bg-white border border-grey-100 rounded-xl">
			<span className="text-grey-500 font-semibold">Upgrade Add-On Credit</span>

			{/* Slider */}
			<div className="flex flex-col gap-4 py-5 px-6 min-h-36 bg-grey-100 rounded-xl justify-between">
				<span className="text-grey-500">Total Monthly Analytics Credit</span>
				<div className="flex items-center h-12 mt-2">
					<Button
						size="xs"
						variant="primary"
						className="rounded-full p-1 h-6 w-6 mr-2"
						onClick={handleDecrease}
					>
						<MinusIcon />
					</Button>
					<Slider
						min={MIN_ADDON}
						max={MAX_ADDON}
						step={STEP_ADDON}
						value={sliderValue}
						onChange={(value) => {
							if ((value as number) < minimumValueAddon) {
								setShowErrorInfo(true);
							} else {
								setShowErrorInfo(false);
							}
							setSliderValue(
								(value as number) < minimumValueAddon
									? minimumValueAddon
									: (value as number)
							);
						}}
						handleRender={(renderProps) => {
							return (
								<div {...renderProps.props}>
									<SliderTooltip
										theme={{
											color: "#000",
											fontSize: "16px",
											fontFamily: "Metric",
											whiteSpace: "normal",
										}}
									>
										{sliderValue}
									</SliderTooltip>
								</div>
							);
						}}
					/>
					<Button
						size="xs"
						variant="primary"
						className="rounded-full p-1 h-6 w-6 ml-2"
						onClick={handleIncrease}
					>
						<PlusIcon />
					</Button>
				</div>
				{showErrorInfo && (
					<div className="text-red-500">
						Please contact us if you want to decrease Analytics Credit.{" "}
						<a
							href="https://api.whatsapp.com/send?phone=6287797700999"
							target="_blank"
							className="underline underline-offset-2"
						>
							Contact Us
						</a>
					</div>
				)}
			</div>

			<div className="flex justify-between">
				<div className="flex flex-col text-grey-500">
					<span className="">Monthly Subscription</span>
					<span className="font-light">
						{subscriptionPlan.analyticsLimit} Monthly Creator Analytics Credit
					</span>
				</div>
				<span className="text-grey-800  font-semibold">
					{formatToIDR(subscriptionPlan.pricePerMonth)}
				</span>
			</div>
			{renderAddOn()}
			{renderUpgradeAddOn()}
			<div className="flex flex-col py-4 gap-1">
				<PaymentComponent
					payload={{
						planID: 1,
						addon: {
							id: planData?.addonPlan.find((x) => x.name === "ADDON")?.id ?? 1,
							quantity:
								getDiffAddOn() /
								(planData?.addonPlan.find((x) => x.name === "ADDON")
									?.analyticsLimit ?? 100),
						},
					}}
				>
					{(isPending: boolean): JSX.Element => (
						<Button
							variant="primary"
							size="sm"
							className=""
							disabled={sliderValue <= minimumValueAddon || isPending}
							startIcon={
								generatePaymentIsPending && (
									<LoaderCircleIcon className="animate-spin" />
								)
							}
						>
							Upgrade {renderUpgradePrice()}
						</Button>
					)}
				</PaymentComponent>
				<Button
					variant="outline"
					size="sm"
					className=""
					onClick={cancelUpgrade}
				>
					Cancel
				</Button>
			</div>

			<hr />

			<span className=" text-grey-500">
				Your Next Recurring Payment on{" "}
				<span className="font-bold">
					{dayjs(endDate).format("MMMM DD, YYYY")}
				</span>
			</span>

			<div className="flex items-center justify-between  text-grey-800">
				<span className=" text-grey-500">
					Total <span className="text-sm text-grey-400">(Before Tax)</span>
				</span>
				<span className="font-semibold">
					{formatToIDR(totalPrice + getPriceAddOn())}
				</span>
			</div>
		</div>
	);
};

export default UpgradeSubscription;
