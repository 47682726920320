import type { forgotPasswordSchema } from "@/pages/auth/forgot-password";
import type { loginSchema } from "@/pages/auth/login";
import type { registerSchema } from "@/pages/auth/register";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { InferType } from "yup";

// Define the return type for each function
type LoginResponse = {
	user: {
		id: number;
		email: string;
		name: string;
		role: string;
		isEmailVerified: boolean;
		createdAt: Date;
		updatedAt: Date;
		companyId: number;
		latestPlan?: {
			startDate: Date;
			endDate: Date;
			status: Date;
			analyticsLimit: number;
			analyticsUsage: number;
		};
	};
	tokens: {
		access: {
			token: string;
			expires: Date;
		};
		refresh: {
			token: string;
			expires: Date;
		};
	};
};

type RegisterResponse = {
	user: {
		id: number;
		email: string;
		name: string;
		role: string;
		isEmailVerified: boolean;
		companyId: number;
		phone: string;
		referralSource: number;
		company: {
			name: string;
			industry: number;
		};
	};
	tokens: {
		access: {
			token: string;
			expires: Date;
		};
		refresh: {
			token: string;
			expires: Date;
		};
	};
};

export const login = async (
	payload: InferType<typeof loginSchema>
): Promise<LoginResponse> => {
	const { data }: AxiosResponse<LoginResponse> = await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/login`,
		payload
	);

	return data;
};

export const forgotPassword = async (
	payload: InferType<typeof forgotPasswordSchema>
): Promise<void> => {
	await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/forgot-password`,
		payload
	);
};

export const resetPassword = async ({
	password,
	token,
}: {
	password: string;
	token: string;
}): Promise<void> => {
	const payload = {
		password: password,
	};
	await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/reset-password?token=${token}`,
		payload
	);
};

export const checkPassword = async (payload: {
	email: string;
	password: string;
}): Promise<LoginResponse> => {
	const { data }: AxiosResponse<LoginResponse> = await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/login`,
		payload
	);

	return data;
};

export const register = async (
	payload: InferType<typeof registerSchema>
): Promise<RegisterResponse> => {
	const mapPayload = {
		...payload,
		industry: payload.industry.value,
		referralSource: payload.referralSource.value,
	};

	const { data }: AxiosResponse<RegisterResponse> = await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/register`,
		mapPayload
	);
	return data;
};

export const sendVerificationEmail = async (token: string): Promise<void> => {
	await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/send-verification-email`,
		{},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const verifyEmail = async (token: string): Promise<void> => {
	await axios.post(
		`${import.meta.env["VITE_BASE_URL_API"]}/auth/verify-email?token=${token}`
	);
};
