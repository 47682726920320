import type { FunctionComponent } from "@/common/types";
import { Link } from "@tanstack/react-router";
import { BanknoteIcon, CircleUserRoundIcon, TagIcon } from "lucide-react";

type sidebarType = {
	label: string;
	href: string;
	icon: React.ReactNode;
};

const settingSidebar = [
	{
		label: "My Account",
		href: "/settings/my-account",
		icon: <CircleUserRoundIcon size={20} />,
	},
	{
		label: "My Subscriptions",
		href: "/settings/my-subscription",
		icon: <TagIcon size={20} />,
	},
	{
		label: "Payment Settings",
		href: "/settings/payment",
		icon: <BanknoteIcon size={20} />,
	},
];
function Sidebar(): FunctionComponent {
	return (
		<aside className="hidden lg:flex flex-col py-5 gap-4 w-1/3 xl:w-1/6 bg-orange-700 sticky top-0 self-start h-screen">
			<span className="text-white font-normal text-md ml-4">Settings</span>

			<ul className="flex flex-col justify-center gap-2">
				{settingSidebar?.map((item: sidebarType, index: number) => (
					<Link
						to={item.href}
						key={index}
						activeProps={{
							style: {
								fontWeight: 500,
								backgroundColor: "#BB6028",
								color: "#FFF",
							},
						}}
						inactiveProps={{
							style: {
								color: "#FFF",
							},
						}}
					>
						<li className="flex items-center py-2 ml-4 text-normal gap-2 rounded">
							{item.icon}
							<span>{item.label}</span>
						</li>
					</Link>
				))}
			</ul>
		</aside>
	);
}
export default Sidebar;
