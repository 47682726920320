import { analyzeTikTokBasic } from "@/services/master-services";
import { analyzeInstagramBasic } from "@/services/master-services";
import { analyzeInstagram, analyzeTikTok } from "@/services/master-services";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

// Define the expected response type from the mutation function
type AnalyzeInstagramResponse = ReturnType<typeof analyzeInstagram>;

export const useAnalyzeInstagram = (): UseMutationResult<
	Awaited<AnalyzeInstagramResponse>,
	unknown,
	Parameters<typeof analyzeInstagram>[0],
	unknown
> => {
	return useMutation({ mutationFn: analyzeInstagram });
};

// Define the expected response type from the mutation function
type AnalyzeTikTokResponse = ReturnType<typeof analyzeTikTok>;

export const useAnalyzeTikTok = (): UseMutationResult<
	Awaited<AnalyzeTikTokResponse>,
	unknown,
	Parameters<typeof analyzeTikTok>[0],
	unknown
> => {
	return useMutation({ mutationFn: analyzeTikTok });
};

export type AnalyzeInstagramBasicResponse = ReturnType<typeof analyzeInstagramBasic>;

export const useAnalyzeInstagramBasic = (): UseMutationResult<
	Awaited<AnalyzeInstagramBasicResponse>,
	unknown,
	Parameters<typeof analyzeInstagramBasic>[0],
	unknown
> => {
	return useMutation({ mutationFn: analyzeInstagramBasic });
};

type AnalyzeTikTokBasicResponse = ReturnType<typeof analyzeTikTokBasic>;

export const useAnalyzeTikTokBasic = (): UseMutationResult<
	Awaited<AnalyzeTikTokBasicResponse>,
	unknown,
	Parameters<typeof analyzeTikTokBasic>[0],
	unknown
> => {
	return useMutation({ mutationFn: analyzeTikTokBasic });
};
