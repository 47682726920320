import axiosInstance from "@/config/axios";
import type { PlansWithAddons, SubscriptionInfo } from "@/types/subscription";

export const getSubscriptionInfo = async (): Promise<SubscriptionInfo> => {
	const { data } = await axiosInstance.get<SubscriptionInfo>(
		`${import.meta.env["VITE_BASE_URL_API"]}/subscription/my`
	);

	return data;
};

export type CreditInfo = Omit<SubscriptionInfo, "totalPrice" | "addon"> & {
	erUsage: number;
	erLimit: number;
};
export const getCreditInfo = async (): Promise<CreditInfo> => {
	const { data } = await axiosInstance.get<CreditInfo>(
		`${import.meta.env["VITE_BASE_URL_API"]}/subscription/credit`
	);

	return data;
};

export const getAllPlans = async (): Promise<PlansWithAddons> => {
	const { data } = await axiosInstance.get<PlansWithAddons>(
		`${import.meta.env["VITE_BASE_URL_API"]}/general/plans`
	);

	return data;
};
