import AnalyticsIconAlt from "@/assets/icons/analytics-icon-alt.png";
import AnalyticsLogo from "@/assets/images/analytics-main-logo.png";
import { cn, hasAnalyticCredit } from "@/common/helper";
import Button from "@/components/ui/button";
import { getCreditInfo } from "@/services/subscription-services";
import tokenServices from "@/services/token-services";
import { useCreditStore } from "@/store/credit-stores";
import type { User } from "@/types/master";
import { Popover, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useRouterState } from "@tanstack/react-router";
import {
	ChevronDown,
	LoaderCircleIcon,
	LogOutIcon,
	PlusIcon,
	SettingsIcon,
	Tag,
} from "lucide-react";
import { Fragment, useEffect } from "react";

type PopoverType = {
	label: string;
	href: string;
	icon: React.ReactNode;
	clickEvent?: () => void;
};

type IHeader = {
	refreshKey: number | null;
};
const Header: React.FC<IHeader> = ({ refreshKey = null }) => {
	const router = useRouterState();
	const navigate = useNavigate();

	const user: User = tokenServices.getUser() || {
		email: null,
		name: null,
		latestPlan: null,
		isEmailVerified: false,
	};

	const handleNavigate = (path: string): void => {
		void navigate({ to: path }).catch((error) => {
			console.error("Navigation error:", error);
		});
	};
	const setCreditData = useCreditStore((state) => state.setCreditInfo);
	const resetCreditInfo = useCreditStore((state) => state.resetCreditInfo);
	const creditInfo = useCreditStore((state) => state.creditInfo);

	const popoverItem: Array<PopoverType> = [
		{
			label: "Settings",
			href: "/settings/my-account",
			icon: <SettingsIcon size={16} />,
		},
		{
			label: "Log out",
			href: "/login",
			icon: <LogOutIcon size={16} />,
			clickEvent: (): void => {
				tokenServices.removeUser();
				resetCreditInfo();
			},
		},
	];

	const { isPending: isCreditInfoPending, refetch } = useQuery({
		queryKey: [`get-credit-info`],
		queryFn: async () => {
			const resp = await getCreditInfo();
			setCreditData(resp);
			return resp;
		},
		retry: 1,

		refetchOnWindowFocus: true,
		enabled: !!user.email,
	});

	useEffect(() => {
		if (user.email && refreshKey)
			refetch().catch((error) => {
				console.error(error);
			});
	}, [refreshKey, user.email, refetch]);

	const renderSubscribeButton = (): JSX.Element => {
		if (
			creditInfo?.subscriptionPlan &&
			creditInfo.subscriptionPlan.name !== "FREE_TRIAL"
		)
			return <></>;
		return (
			<Button
				size="sm"
				variant="secondary"
				onClick={() => {
					handleNavigate("/pricing");
				}}
				className="bg-gradient-to-r from-orange-600 to-orange-400 text-white rounded-xl w-32"
			>
				<Tag size={16} className="mr-2" color="#fff"></Tag>
				Subscribe
			</Button>
		);
	};
	return (
		<header className="flex w-full h-20 max-h-40 bg-white border border-b-grey-100 shadow-sm py-4 justify-center">
			<div className="flex w-full max-w-screen-2xl justify-between mx-2">
				{/* Company Logo */}
				<div className="flex items-center gap-2">
					<div
						className="w-20 md:w-40 h-fit cursor-pointer"
						onClick={() => {
							user.email && user.isEmailVerified
								? handleNavigate("/discover")
								: handleNavigate("/");
						}}
					>
						<img src={AnalyticsLogo} alt="analytics-logo" />
					</div>
					{(!user.email || !user.isEmailVerified) && (
						<div className="mx-2 md:mx-8 w-32">
							<Button
								size="sm"
								variant="secondary"
								onClick={() => {
									handleNavigate("/pricing");
								}}
							>
								Request Trial
							</Button>
						</div>
					)}
				</div>

				{user.email === null || !user.isEmailVerified ? (
					<div className="flex justify-end w-full items-center">
						<div className="flex gap-4 w-fit">
							<Button
								size="sm"
								className="hidden md:block"
								variant="secondary"
								onClick={() => {
									handleNavigate("/register");
								}}
							>
								Register
							</Button>
							<Button
								size="sm"
								variant="primary"
								onClick={() => {
									handleNavigate("/login");
								}}
							>
								Login
							</Button>
						</div>
					</div>
				) : (
					<div className="flex justify-end w-full items-center gap-4">
						{renderSubscribeButton()}

						<div
							className={cn(
								"flex flex-row flex-nowrap items-center p-2 pl-3 gap-2 w-fit border border-orange-300 rounded-3xl",
								!creditInfo?.subscriptionPlan ||
									creditInfo.subscriptionPlan.name == "FREE_TRIAL"
									? "hidden sm:flex"
									: ""
							)}
						>
							<span className="text-grey-400 font-medium hidden md:block">
								Analytics Credit
							</span>
							<div className="flex items-center">
								<img src={AnalyticsIconAlt} alt="icons" />
								<span className="font-semibold text-orange-700">
									{isCreditInfoPending && (
										<LoaderCircleIcon className="animate-spin" />
									)}
									{creditInfo && hasAnalyticCredit(creditInfo)
										? creditInfo.analyticsLimit - creditInfo.analyticsUsage
										: 0}
								</span>
							</div>
							<Button
								size="xs"
								variant="primary"
								className="rounded-full w-fit p-1"
								onClick={() => {
									handleNavigate("/settings/my-subscription");
								}}
							>
								<PlusIcon />
							</Button>
						</div>

						{/* <div>
							<button className="flex justify-center items-center w-6 h-6">
								<Bell color="#BFBFBF" fill="#BFBFBF" size={24} />
							</button>
						</div> */}

						<Popover className="flex justify-end relative ml-4">
							<Popover.Button className="flex justify-end items-center gap-4  text-grey-500 font-medium focus:outline-none">
								<span className="capitalize text-end">
									{user ? user.name : "John Doe"}
								</span>
								<ChevronDown color="#5B5C5E" />
							</Popover.Button>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-0"
								enterTo="opacity-100 translate-y-1"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-1"
								leaveTo="opacity-0 translate-y-0"
							>
								<Popover.Panel className="absolute z-10 mt-8 w-28 bg-white p-2 shadow rounded">
									<ul className="flex flex-col gap-2 items-center">
										{popoverItem.map((item: PopoverType, index: number) => (
											<Link
												to={item.href}
												activeProps={{
													style: {
														fontWeight: "bold",
														backgroundColor: "#F8F8F8",
													},
												}}
												key={index}
												className="w-full justify-center flex"
												onClick={item.clickEvent ?? undefined}
											>
												<li
													className={cn(
														"flex items-center gap-2 py-2 hover:bg-grey-100 rounded w-full px-2 justify-around",
														router.location.pathname.includes("/settings") &&
															item.href.includes("/settings") &&
															"font-semibold bg-grey-100",
														router.location.pathname.includes(
															"/my-subscription"
														) &&
															item.href.includes("/my-subscription") &&
															"font-semibold bg-grey-100"
													)}
												>
													{item.icon}
													<span className="text-sm">{item.label}</span>
												</li>
											</Link>
										))}
									</ul>
								</Popover.Panel>
							</Transition>
						</Popover>
					</div>
				)}
			</div>
		</header>
	);
};

export default Header;
