import { AuthLayout } from "@/components/layout";
import Button from "@/components/ui/button";
import { useSendVerificationEmail } from "@/hooks/auth-hooks";
import tokenServices from "@/services/token-services";
import { Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { ArrowLeftIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AnalyticsLogo from "@/assets/images/analytics-main-logo.png";

const EmailVerification = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const [resendCount, setResendCount] = useState<number>(60);

	const email = tokenServices.getUserEmail();
	const token = tokenServices.getLocalAccessToken() as string;

	const sendVerificationEmail = useSendVerificationEmail();
	const navigate = useNavigate();

	const handleEmailSend = (): void => {
		sendVerificationEmail.mutate(token, {
			onSuccess: () => {
				toast.success("Check your email");
				setLoading(false);
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message);
				} else {
					console.error("Unexpected error:", error);
				}
				setLoading(false);
			},
		});
	};

	const onSubmit = (): void => {
		setResendCount(60);
		handleEmailSend();
	};

	useEffect(() => {
		if (email && token) {
			handleEmailSend();
		} else {
			navigate({ to: "/" }).catch((error) => {
				console.error(error);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty dependency array to ensure this runs only once on mount

	useEffect(() => {
		if (resendCount > 0) {
			const timer = setInterval(() => {
				setResendCount((previousCount) => previousCount - 1);
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		}
		return;
	}, [resendCount]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<AuthLayout>
			<div className="flex flex-col w-full flex-grow sm:justify-center items-center h-full px-4">
				<div className="self-center flex sm:hidden py-10">
					<img
						src={AnalyticsLogo}
						alt="icon"
						className="w-[200px] cursor-pointer"
						onClick={(): void => {
							navigate({ to: "/" }).catch((error) => {
								console.error(error);
							});
						}}
					/>
				</div>
				<Link
					to="/login"
					className="flex items-center gap-2 text-grey-400 hover:text-orange-500/80 w-fit self-start mb-8"
				>
					<ArrowLeftIcon />
					<span className="font-semibold ">Back</span>
				</Link>

				<div className="flex flex-col gap-2 text-grey-800 w-full">
					<span className="font-semibold text-4xl">Email Verification</span>

					<div className="flex flex-col gap-4 my-4">
						<span className="text-sm text-grey-400">
							Please click the link that we have sent to your email.
						</span>
						<span className=" font-medium mt-2">{email ?? "-"}</span>
					</div>
					<Button
						size="md"
						variant="primary"
						onClick={onSubmit}
						disabled={resendCount > 0}
					>
						Resend Email Verification{" "}
						{resendCount > 0 ? `(${resendCount})` : ""}
					</Button>
				</div>
			</div>
		</AuthLayout>
	);
};

export default EmailVerification;
