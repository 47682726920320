import CoinIcon from "@/assets/icons/analytics-icon-white.svg";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import { cn, hasAnalyticCredit } from "@/common/helper";
import type { FunctionComponent } from "@/common/types";
import tokenServices from "@/services/token-services";
import { useCreditStore } from "@/store/credit-stores";
import type {
	AnalyticsSource,
	SocialMediaPlatform,
	User,
} from "@/types/master";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, Percent, SearchIcon } from "lucide-react";
import type React from "react";
import { Fragment, useEffect, useRef, useState } from "react";

interface ISearchBar {
	onSearchUser?: (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	) => void;
	onSearchUserBasic: (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	) => void;
	defaultValue?: string;
	defaultOption: SocialMediaPlatform;
	isLoading?: boolean;
}

const options: Array<{ name: string; value: SocialMediaPlatform }> = [
	{ name: "Instagram", value: "instagram" },
	{ name: "TikTok", value: "tiktok" },
];

const SearchBar = ({
	onSearchUser,
	onSearchUserBasic,
	defaultValue = "",
	defaultOption = "instagram",
	isLoading = false,
}: ISearchBar): FunctionComponent => {
	const [selected, setSelected] = useState(options[0]);
	const [searchQuery, setSearchQuery] = useState<string>(defaultValue);
	const previousIsLoadingRef = useRef<boolean>(isLoading);

	useEffect(() => {
		if (previousIsLoadingRef.current && !isLoading) {
			setSearchQuery("");
		}
		previousIsLoadingRef.current = isLoading;
	}, [isLoading]);

	const user: User = tokenServices.getUser() || {
		email: null,
		name: null,
		latestPlan: null,
		isEmailVerified: false,
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const query = event.target.value;
		setSearchQuery(query);
	};

	useEffect(() => {
		if (defaultValue) {
			setSearchQuery(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (defaultOption) {
			setSelected(options.find((x) => x.value == defaultOption));
		}
	}, [defaultOption]);

	const creditData = useCreditStore();
	const handleSearch = (): void => {
		if (!selected) return;
		if (
			hasAnalyticCredit(creditData.creditInfo) &&
			typeof onSearchUser === "function"
		) {
			setSearchQuery("");
			onSearchUser(searchQuery, selected.value, "SEARCH");
		} else if (typeof onSearchUserBasic === "function") {
			setSearchQuery("");
			onSearchUserBasic(searchQuery, selected.value, "SEARCH");
		}
	};

	const renderTag = (): JSX.Element => {
		if (hasAnalyticCredit(creditData.creditInfo)) {
			return (
				<div
					className="mr-4 h-8 w-8 sm:w-auto flex items-center cursor-pointer"
					onClick={handleSearch}
				>
					<button className="flex justify-center items-center text-orange-700 font-semibold gap-2 py-1 px-1 sm:px-2  w-full border border-orange-300 focus:outline-none bg-orange-100 sm:bg-transparent hover:bg-orange-200 hover:rounded-3xl rounded-2xl">
						<div className="flex items-center justify-center w-5 h-5 bg-orange-500 rounded-full">
							<img src={CoinIcon} alt="" />
						</div>
						<span className="hidden sm:block whitespace-nowrap">Analyze</span>
					</button>
				</div>
			);
		}
		return (
			<div
				className="mr-4 h-8 w-8 sm:w-auto flex items-center cursor-pointer"
				onClick={handleSearch}
			>
				<button
					className="flex flex-row justify-center
					items-center text-green-500 font-semibold gap-2 py-1 px-1 sm:px-2 w-full border border-green-500 focus:outline-none bg-green-100 sm:bg-transparent hover:bg-green-200 hover:rounded-3xl rounded-2xl"
				>
					<div className="flex items-center justify-center w-5 h-5 bg-green-500 rounded-full">
						<Percent size={12} color="#fff"></Percent>
					</div>
					<span className="hidden sm:block whitespace-nowrap">
						ER Calculator
						{user.email && user.isEmailVerified && (
							<span className="ml-1">
								(
								{(creditData?.creditInfo?.erLimit ?? 0) -
									(creditData?.creditInfo?.erUsage ?? 0)}
								)
							</span>
						)}
					</span>
				</button>
			</div>
		);
	};

	return (
		<div
			className={cn(
				"w-full flex items-center ring-1 rounded-xl border transition-colors focus-visible:outline-none focus-visible:ring-1",
				!user.email || hasAnalyticCredit(creditData.creditInfo)
					? "ring-orange-500"
					: "ring-green-500"
			)}
		>
			<div className="min-w-[80px] sm:min-w-[150px]">
				<Listbox value={selected} onChange={setSelected}>
					<div className="relative">
						<Listbox.Button
							className={`flex border-white justify-center items-center w-full
							cursor-pointer rounded-xl bg-white h-12 py-2 pl-3
							text-left border-l border-y border-r-0 focus:outline-none`}
						>
							<span className="flex items-center gap-2 truncate">
								{selected?.name === "Instagram" ? (
									<img src={InstaIcon} alt="" />
								) : (
									<img src={TiktokIcon} alt="" />
								)}
								<span className="hidden sm:block">{selected?.name}</span>
							</span>
							<span className="pointer-events-none inset-y-0 right-0 flex items-center px-2">
								<ChevronDownIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								className={cn(
									"absolute mt-1 max-h-60 w-full min-w-[132px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none",
									hasAnalyticCredit(creditData.creditInfo)
										? "ring-orange-500"
										: "ring-green-500"
								)}
							>
								{options.map((option, optionIndex) => (
									<Listbox.Option
										key={optionIndex}
										className={({ active }) =>
											`cursor-pointer select-none py-2 px-2 sm:px-4 ${
												active ? "bg-grey-100 text-gray-900" : "text-gray-900"
											}`
										}
										value={option}
									>
										{({ selected }) => (
											<div className={`flex items-center gap-2 px-2`}>
												{option.name === "Instagram" ? (
													<img src={InstaIcon} alt="icon" />
												) : (
													<img src={TiktokIcon} alt="icon" />
												)}
												<span
													className={`block truncate ${
														selected
															? "font-medium"
															: "font-normal text-grey-400"
													}`}
												>
													{option.name}
												</span>
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</Listbox>
			</div>

			<div className="h-7 border-l top-[11px] border-grey-150"></div>
			<SearchIcon
				className="top-4 w-10 mx-2 self-center"
				size={20}
				color="#BFBFBF"
			/>
			<input
				type="text"
				disabled={isLoading}
				className={cn(
					"border-none placeholder:text-muted-foreground flex h-12 w-full bg-transparent p-0 sm:p-4 text-grey-800 shadow-sm disabled:cursor-not-allowed disabled:opacity-50 outline-none"
				)}
				placeholder="Enter Username Here"
				onChange={handleChange}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						selected ? handleSearch() : null;
					}
				}}
				value={searchQuery}
			/>
			{user.email && renderTag()}
		</div>
	);
};

export default SearchBar;
