import { Home } from "@/pages/Home";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
	component: Home,
	beforeLoad: () => {
		if (
			tokenServices.getLocalAccessToken() &&
			tokenServices.getLocalVerifToken()
		) {
			throw redirect({ to: "/discover" });
		}
	},
});
