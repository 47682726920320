import { createFileRoute, redirect } from "@tanstack/react-router";
import ForgotPassword from "@/pages/auth/forgot-password";
import tokenServices from "@/services/token-services";

export const Route = createFileRoute("/forgot-password")({
	component: ForgotPassword,
	beforeLoad: ({ location }) => {
		if (tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login", search: location.href });
		}
	},
});
