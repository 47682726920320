import type { InstagramPublic } from "@/types/instagram";
import type { TikTokPublic } from "@/types/tiktok";
import AnalyticsLogogram from "@/assets/images/analytics_logogram.png";

export const dummyProfileDataIG: InstagramPublic = {
	instagramId: "123456",
	username: "creatoranalytics",
	following: 1312,
	follower: 224_579,
	avatarUrl: AnalyticsLogogram,
	name: "Creator Analytics",
	bio: "Creator Analytics by InfluenceID",
	isVerified: true,
	externalUrls: ["https://analytics.influence.id"],
	post: 270,
	like: 176_992,
	comment: 15_676,
	category: "BUSINESS",
	isBusinessAccount: true,
	isProfessionalAccount: true,
	view: 836_815,
	viewRate: 53.230_711_687_201_385,
	avgErPercent: "7.149228853395316",
	engagement: "16055.666666666666",
	updatedAt: "2024-07-04T09:57:40.738Z",
	avgLikes: 14_749.33,
	avgComments: 1306.33,
	avgViews: 119_545,
	instagramPosts: [],
	searchType: "ER_CALC",
};
export const dummyProfileDataTikTok: TikTokPublic = {
	tiktokId: "012345678910",
	secUid: "asdfghjkl",
	username: "creatoranalytics",
	name: "Creator Analytics",
	bio: "Creator Analytics by InfluenceID",
	follower: 224_579,
	following: 1312,
	avatarUrl: AnalyticsLogogram,
	isVerified: true,
	like: 12_900_000,
	postCount: 270,
	friendCount: 22,
	avgLikes: 32_992.327_365_728_9,
	avgComments: 1306.33,
	avgViews: 119_545,
	engagementRate: "0",
	avgEngagement: "0",
	updatedAt: "2024-05-29T03:07:50.799Z",
	tiktokPost: [],
	searchType: "ER_CALC",
	externalUrls: ["https://analytics.influence.id"],
	viewRate: 89.12,
};
