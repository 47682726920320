import type { CreditInfo } from "@/services/subscription-services";
import { create } from "zustand";

interface ICreditStore {
	creditInfo: CreditInfo | null;
	setCreditInfo: (data: CreditInfo) => void;
	resetCreditInfo: () => void;
}

export const useCreditStore = create<ICreditStore>()((set) => ({
	creditInfo: null,
	setCreditInfo: (newData: CreditInfo): void => {
		set({ creditInfo: newData });
	},
	resetCreditInfo: (): void => {
		set({ creditInfo: null });
	},
}));
