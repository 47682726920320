import CoinIcon from "@/assets/icons/coin-icon.png";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import Tooltip from "@/components/ui/tooltip";
import type { ListAnalytics, ListItem } from "@/services/master-services";
import { useFeaturedCreatorStore } from "@/store/featured-creator-store";
import type { SocialMediaPlatform } from "@/types/master";
import dayjs from "dayjs";
import { InfoIcon, Percent, TriangleAlert, UserSearch } from "lucide-react";
import type { UUID } from "node:crypto";

function groupAndSortByDay(
	data: Array<ListItem>
): Record<string, Array<ListItem>> {
	const groupedData: Record<string, Array<ListItem>> = {};

	data.forEach((user) => {
		const date = user.updatedAt ? new Date(user.updatedAt) : new Date();
		const day = date.toISOString().split("T")[0]; // Extract the day in 'YYYY-MM-DD' format

		if (day) {
			// Ensure day is defined
			if (!(day in groupedData)) {
				groupedData[day] = [];
			}
			(groupedData[day] as Array<ListItem>).push(user);
		}
	});

	// Sort each day's entries by hours descending
	Object.keys(groupedData).forEach((day) => {
		(groupedData[day] as Array<ListItem>).sort((a, b) => {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		});
	});

	return groupedData;
}
interface IRequestAnalysis {
	listData: ListAnalytics;
	handleDetailUser: (uuid: UUID, platform: SocialMediaPlatform) => void;
}

const errorDesc = [
	{
		code: -6,
		description: "Data Not Sufficient",
	},
	{
		code: -5,
		description: "No Posts Yet",
	},
	{
		code: -4,
		description: "Follower < 1,000 ",
	},
	{
		code: -3,
		description: "Private Account",
	},
	{
		code: -2,
		description: "Username Not Found",
	},
	{
		code: -1,
		description: "Technical Issue",
	},
];

const tooltipsCopy = [
	{
		code: -6,
		description:
			"Analysis failed: Insufficient audience data for analysis. Your analytics credit has been refunded.",
	},
	{
		code: -5,
		description:
			"Analysis failed: No posts found on creator's account. Your analytics credit has been refunded.",
	},
	{
		code: -4,
		description:
			"Analysis failed: Creator's followers are less than 1,000. Your analytics credit has been refunded.",
	},
	{
		code: -3,
		description:
			"Analysis failed: Creator's account is private. Your analytics credit has been refunded.",
	},
	{
		code: -2,
		description:
			"Analysis failed: The creator’s username was not found on the selected platform. Your analytics credit has been refunded.",
	},
	{
		code: -1,
		description:
			"Technical Issue: We're still working to resolve this. Your analytics credit has been refunded.",
	},
	{
		code: 1,
		description: "Your analysis will be ready in approximately 10 minutes.",
	},
	{
		code: 2,
		description:
			"The process is taking longer than expected. We appreciate your patience.",
	},
];

const RequestAnalysis: React.FC<IRequestAnalysis> = ({
	listData,
	handleDetailUser,
}) => {
	const listFreeCreatorStore = useFeaturedCreatorStore();
	const renderButtonAnalyze = (user: ListItem): JSX.Element => {
		if (user.progress === 300) {
			if (
				listFreeCreatorStore.freeCreatorList.some(
					(x) => x.username == user.username && x.platform == user.platform
				)
			) {
				return (
					<button
						className="flex justify-between gap-2 items-center text-orange-700 font-medium py-1 px-2 pl-3 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 rounded-2xl"
						onClick={() => {
							handleDetailUser(user.uuid, user.platform);
						}}
					>
						<span className="">Analyze</span>
						<span className="flex items-center text-[14px] py-1 px-2 text-white font-medium justify-center bg-orange-500 rounded-2xl">
							FREE
						</span>
					</button>
				);
			} else if (user.searchType === "ANALYTICS")
				return (
					<button
						className="flex justify-center items-center text-orange-700 font-medium gap-2 py-1 px-2 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 hover:rounded-3xl rounded-2xl"
						onClick={() => {
							handleDetailUser(user.uuid, user.platform);
						}}
					>
						<div className="flex items-center justify-center w-5 h-5 bg-orange-500 rounded-full">
							<img src={CoinIcon} alt="" />
						</div>
						<span className="">Analyze</span>
					</button>
				);
			return (
				<button
					className="flex flex-row flex-wrap justify-center
					items-center text-green-500 font-medium gap-2 py-1 px-2 w-full border border-green-500 focus:outline-none hover:bg-green-200 hover:rounded-3xl rounded-2xl"
					onClick={() => {
						handleDetailUser(user.uuid, user.platform);
					}}
				>
					<div className="flex items-center justify-center w-5 h-5 bg-green-500 rounded-full">
						<Percent size={12} color="#fff"></Percent>
					</div>
					<span className="whitespace-nowrap">ER Calculator</span>
				</button>
			);
		} else if (user.progress === 0) {
			const prepButton = (
				<button
					disabled
					className="flex flex-nowrap justify-center items-center cursor-progress text-grey-200 font-medium gap-2 w-full"
				>
					Preparing{" "}
					{user.attemptsMade >= 3 ? (
						<TriangleAlert
							color="#FAA61A"
							className="mb-[1px]"
							size={16}
						></TriangleAlert>
					) : (
						<InfoIcon className="mb-[1px]" size={16} />
					)}
				</button>
			);
			return (
				<Tooltip
					tooltipContent={
						tooltipsCopy.find(
							(x) => x.code === (user.attemptsMade >= 3 ? 2 : 1)
						)?.description
					}
				>
					{prepButton}
				</Tooltip>
			);
		}

		return (
			<Tooltip
				tooltipContent={
					tooltipsCopy.find((x) => x.code == user.progress)?.description
				}
			>
				<button
					disabled
					className="flex justify-center items-center text-red-500 font-medium gap-2 w-full"
				>
					<span className="items-center text-xs py-1 pb-1.5 px-2 text-grey-200 font-medium justify-center  rounded-2xl border border-grey-200 hidden sm:flex">
						{errorDesc.find((x) => x.code === user.progress)?.description}
					</span>{" "}
					Failed
					<TriangleAlert
						color="red"
						className="mb-[1px]"
						size={16}
					></TriangleAlert>
				</button>
			</Tooltip>
		);
	};

	const renderListData = (listData: ListAnalytics): JSX.Element => {
		const groupedData = groupAndSortByDay(listData.data);
		return (
			<>
				{Object.entries(groupedData).map(([day, users]) => (
					<div key={day}>
						<span className="text-sm text-grey-400 font-medium">
							{dayjs(day).format("MMMM DD, YYYY")}
						</span>
						<ul className="flex flex-col gap-2 mt-2">
							{users.map((user) => (
								<li
									key={user.uuid}
									className="flex justify-between items-center gap-2 my-2 sm:my-0"
								>
									<div className="flex items-center gap-2">
										<div>
											<img
												src={
													user.platform == "instagram" ? InstaIcon : TiktokIcon
												}
												alt="insta-icon"
											/>
										</div>
										<div className="flex gap-0 sm:gap-2 flex-col sm:flex-row">
											{user.name && (
												<span className="text-grey-800 font-medium">
													{user.name}
												</span>
											)}
											<span className=" text-grey-400 font-medium">
												@{user.username}
											</span>
										</div>
									</div>
									<div>{renderButtonAnalyze(user)}</div>
								</li>
							))}
						</ul>
					</div>
				))}
			</>
		);
	};

	return (
		<div className="flex flex-col py-5 px-5 sm:px-7 gap-4 bg-grey-100 text-grey-800 rounded-xl shadow">
			<div className="flex items-center gap-5">
				<span className="font-semibold">Request Analysis</span>
			</div>

			<div className="flex flex-col gap-4">
				{listData && listData.data.length > 0 ? (
					renderListData(listData)
				) : (
					<div className="flex flex-col justify-center items-center py-16">
						<UserSearch size={48} color="#808080"></UserSearch>
						<span className="text-grey-400 my-4">No Request Analysis Data</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default RequestAnalysis;
