import ResetPassword from "@/pages/auth/reset-password";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/reset-password")({
	component: ResetPassword,
	beforeLoad: ({ location }) => {
		if (tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login", search: location.href });
		}
	},
	validateSearch: (search: Record<string, unknown>): { token: string } => {
		return {
			token: search["token"] as string,
		};
	},
});
