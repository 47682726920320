import {
	checkPassword,
	forgotPassword,
	login,
	register,
	resetPassword,
	sendVerificationEmail,
	verifyEmail,
} from "@/services/auth-services";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

// Define the expected response types from the mutation functions
type LoginResponse = ReturnType<typeof login>;
type ForgotPasswordResponse = ReturnType<typeof forgotPassword>;
type ResetPasswordResponse = ReturnType<typeof resetPassword>;
type CheckPasswordResponse = ReturnType<typeof checkPassword>;
type RegisterResponse = ReturnType<typeof register>;
type SendVerificationEmailResponse = ReturnType<typeof sendVerificationEmail>;
type VerifyEmailResponse = ReturnType<typeof verifyEmail>;

export const useLogin = (): UseMutationResult<
	Awaited<LoginResponse>,
	unknown,
	Parameters<typeof login>[0],
	unknown
> => {
	return useMutation({ mutationFn: login });
};

export const useForgotPassword = (): UseMutationResult<
	Awaited<ForgotPasswordResponse>,
	unknown,
	Parameters<typeof forgotPassword>[0],
	unknown
> => {
	return useMutation({ mutationFn: forgotPassword });
};

export const useResetPassword = (): UseMutationResult<
	Awaited<ResetPasswordResponse>,
	unknown,
	Parameters<typeof resetPassword>[0],
	unknown
> => {
	return useMutation({ mutationFn: resetPassword });
};

export const useCheckPassword = (): UseMutationResult<
	Awaited<CheckPasswordResponse>,
	unknown,
	Parameters<typeof checkPassword>[0],
	unknown
> => {
	return useMutation({ mutationFn: checkPassword });
};

export const useRegister = (): UseMutationResult<
	Awaited<RegisterResponse>,
	unknown,
	Parameters<typeof register>[0],
	unknown
> => {
	return useMutation({ mutationFn: register });
};

export const useSendVerificationEmail = (): UseMutationResult<
	Awaited<SendVerificationEmailResponse>,
	unknown,
	Parameters<typeof sendVerificationEmail>[0],
	unknown
> => {
	return useMutation({ mutationFn: sendVerificationEmail });
};

export const useVerifyEmail = (): UseMutationResult<
	Awaited<VerifyEmailResponse>,
	unknown,
	Parameters<typeof verifyEmail>[0],
	unknown
> => {
	return useMutation({ mutationFn: verifyEmail });
};
