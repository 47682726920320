import { validatePromo } from "@/services/payment-services";
import { generatePayment, requestFreeTrial } from "@/services/payment-services";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

// Define the expected response type from the mutation function
type GeneratePaymentResponse = ReturnType<typeof generatePayment>;

export const useGeneratePayment = (): UseMutationResult<
	Awaited<GeneratePaymentResponse>,
	unknown,
	Parameters<typeof generatePayment>[0],
	unknown
> => {
	return useMutation({ mutationFn: generatePayment });
};

type RequestFreeTrialResponse = ReturnType<typeof requestFreeTrial>;

export const useRequestFreeTrial = (): UseMutationResult<
	Awaited<RequestFreeTrialResponse>,
	unknown,
	Parameters<typeof requestFreeTrial>,
	unknown
> => {
	return useMutation({ mutationFn: requestFreeTrial });
};

type ValidatePromoResponse = ReturnType<typeof validatePromo>;

export const useValidatePromo = (): UseMutationResult<
	Awaited<ValidatePromoResponse>,
	unknown,
	Parameters<typeof validatePromo>[0],
	unknown
> => {
	return useMutation({ mutationFn: validatePromo });
};
