import { Link } from "@tanstack/react-router";
import { BanknoteIcon, CircleUserRoundIcon, TagIcon } from "lucide-react";

const mobileMenuItems = [
	{
		label: "My Account",
		href: "/settings/my-account",
		icon: <CircleUserRoundIcon size={20} />,
	},
	{
		label: "My Subscriptions",
		href: "/settings/my-subscription",
		icon: <TagIcon size={20} />,
	},
	{
		label: "Payment Settings",
		href: "/settings/payment",
		icon: <BanknoteIcon size={20} />,
	},
];

const MenuBar = (): JSX.Element => {
	return (
		<nav className="flex lg:hidden justify-around items-center bg-orange-700 h-12 px-4">
			{mobileMenuItems.map((item, index) => (
				<Link
					to={item.href}
					key={index}
					activeProps={{
						style: {
							fontWeight: 500,
							color: "#FFF",
							borderBottomWidth: 2,
							borderBottomColor: "#fff",
						},
					}}
					inactiveProps={{
						style: {
							color: "#FFF",
						},
					}}
					className="h-full items-center pt-2"
				>
					<div className="flex flex-col items-center justify-between">
						{item.icon}
						<span className="text-xs mt-0.5">{item.label}</span>
					</div>
				</Link>
			))}
		</nav>
	);
};

export default MenuBar;
