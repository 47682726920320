import { cn, formatNumber } from "@/common/helper";
import { Copy, InfoIcon, LockKeyhole } from "lucide-react";
import Tooltip from "../ui/tooltip";
import toast from "react-hot-toast";

export type TCreatorPerformance = {
	engagement: string;
	avgLikes: number;
	avgComments: number;
	avgViews: number;
	viewRate: number;
	postCount?: number;
	videoCount?: number;
};
interface ICreatorPerformance {
	profileData: TCreatorPerformance;
	isDummy: boolean;
}
const tooltip = [
	"The average percentage of interactions with recent posts, calculated based on likes, comments, and shares.",
	"The average number of likes received on recent posts.",
	"The average number of comments received on recent posts.",
	"The average number of views received on recent video reels.",
	"The average number of views each content receives, based on recent posts.",
];
const CreatorPerformance: React.FC<ICreatorPerformance> = ({
	profileData,
	isDummy,
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center gap-2">
				<div className="h-4 border-l-4 border-orange-500 rounded-3xl"></div>
				<span className="text-lg font-semibold">Creator Performance</span>
			</div>

			<div className="flex flex-wrap gap-4">
				<div className="flex flex-col flex-1 rounded-xl bg-white p-4 gap-2">
					<Tooltip tooltipContent={tooltip[0]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Avg. Engagements</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"flex items-center text-xl font-semibold group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.engagement) {
									navigator.clipboard
										.writeText(
											formatNumber(Number.parseFloat(profileData.engagement), 0)
										)
										.then(() => {
											toast.success("Engagement has been copied to clipboard");
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696"></LockKeyhole>
							) : profileData ? (
								<>
									{Number.parseFloat(profileData.engagement) > 0
										? formatNumber(Number.parseFloat(profileData.engagement), 0)
										: "-"}

									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>
				<div className="flex flex-col flex-1 rounded-xl bg-white p-4 gap-2">
					<Tooltip tooltipContent={tooltip[1]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Avg. Likes</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"flex items-center text-xl font-semibold group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.avgLikes) {
									navigator.clipboard
										.writeText(formatNumber(profileData.avgLikes, 0))
										.then(() => {
											toast.success(
												"Average Likes has been copied to clipboard"
											);
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData?.avgLikes ? (
								<>
									{profileData.avgLikes > 0
										? formatNumber(profileData.avgLikes, 0)
										: "-"}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 rounded-xl bg-white p-4 gap-2">
					<Tooltip tooltipContent={tooltip[2]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Avg. Comments</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"flex items-center text-xl font-semibold group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.avgComments) {
									navigator.clipboard
										.writeText(formatNumber(profileData.avgComments, 0))
										.then(() => {
											toast.success(
												"Average Comments has been copied to clipboard"
											);
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData?.avgComments ? (
								<>
									{profileData.avgComments > 0
										? formatNumber(profileData.avgComments, 0)
										: "-"}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 rounded-xl bg-white p-4 gap-2">
					<Tooltip tooltipContent={tooltip[3]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>Avg. Reel Views</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"flex items-center text-xl font-semibold group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.avgViews) {
									navigator.clipboard
										.writeText(formatNumber(profileData.avgViews, 0))
										.then(() => {
											toast.success(
												"Average Reel Views has been copied to clipboard"
											);
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData?.avgViews ? (
								<>
									{profileData.avgViews > 0
										? formatNumber(profileData.avgViews, 0)
										: "-"}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 rounded-xl bg-white p-4 gap-2">
					<Tooltip tooltipContent={tooltip[4]}>
						<div className="flex items-center text-grey-500 gap-2">
							<span>View Rate</span>
							<InfoIcon size={16} />
						</div>
					</Tooltip>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"flex items-center text-xl font-semibold group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.viewRate) {
									navigator.clipboard
										.writeText(
											profileData.viewRate > 0
												? formatNumber(profileData.viewRate, 0) + "%"
												: "-"
										)
										.then(() => {
											toast.success("View Rate has been copied to clipboard");
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData?.viewRate ? (
								<>
									{profileData.viewRate > 0
										? formatNumber(profileData.viewRate, 0) + "%"
										: "-"}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								"0%"
							)}
						</span>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default CreatorPerformance;
