import { Controller, useFormContext } from "react-hook-form";
import type { CSSObjectWithLabel, GroupBase, Props } from "react-select";
import ReactSelect from "react-select";
import Label from "../ui/label";

type SelectFormType<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group> & {
	name: string;
	label?: string;
	required?: boolean;
};

const customStyles = {
	placeholder: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
		...styles,
		color: "#969696",
		fontSize: 18,
		fontWeight: 400,
	}),
	control: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
		...styles,
		height: "2.3em",
		borderColor: "rgb(232, 232, 232)",
		boxShadow: `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`,
		borderRadius: "0.4em",
		fontSize: 18,
		"&:hover": {
			...styles[":hover"],
			cursor: "text",
		},
		"&:focus-within": {
			...styles[":focus-within"],
			boxShadow: `0 0 0 1px #FAA61A`,
		},
	}),
	option: (
		styles: CSSObjectWithLabel,
		{
			data,
			isSelected,
			isFocused,
			isDisabled,
		}: {
			data: { color?: string };
			isSelected: boolean;
			isFocused: boolean;
			isDisabled: boolean;
		}
	): CSSObjectWithLabel => ({
		...styles,
		cursor: isDisabled ? "not-allowed" : "auto",
		fontSize: 16,
		backgroundColor: isDisabled
			? undefined
			: isSelected
				? "#E68C24"
				: isFocused
					? "#FAA61A"
					: undefined,
		color: isDisabled
			? "hsl(0, 0%, 80%)"
			: isSelected
				? "#fff"
				: isFocused
					? "#F8F8F8"
					: data.color ?? "inherit",
	}),
};

const customErrorStyles = {
	placeholder: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
		...styles,
		color: "#969696",
		fontSize: 18,
		fontWeight: 400,
	}),
	control: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
		...styles,
		boxShadow: `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`,
		borderColor: "rgb(232, 232, 232)",
		borderRadius: "2em",
		"&:hover": {
			...styles[":hover"],
			boxShadow: `0 0 0 1px rgb(239 68 68)`,
			cursor: "text",
		},
		"&:focus-within": {
			...styles[":focus-within"],
			boxShadow: `0 0 0 1px rgb(239 68 68)`,
		},
	}),
};

const SelectForm = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(
	props: SelectFormType<Option, IsMulti, Group>
): React.ReactElement => {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name={props.name}
			defaultValue={null}
			render={({ field, fieldState }) => (
				<div className="flex flex-col gap-2">
					<div className="flex items-start gap-1">
						<Label className="text-[18px] text-grey-800">{props.label}</Label>
						{props.required && (
							<Label className="text-sm text-red-600">*</Label>
						)}
					</div>
					<ReactSelect
						{...field}
						{...props}
						menuPosition="fixed"
						styles={fieldState.invalid ? customErrorStyles : customStyles}
					/>
					{fieldState.invalid && (
						<small className="text-sm text-red-600">
							{fieldState.error?.message}
						</small>
					)}
				</div>
			)}
		/>
	);
};

export default SelectForm;
