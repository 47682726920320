import type { FunctionComponent } from "@/common/types";
import { AuthLayout } from "@/components/layout";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useResetPassword } from "@/hooks/auth-hooks";
import { Route } from "@/routes/reset-password";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import {
	CheckIcon,
	LoaderCircleIcon,
	LockKeyholeIcon,
	XIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

const resetPasswordSchema = yup.object({
	newPassword: yup
		.string()
		.required()
		.matches(
			/^(?=.*[A-Z])(?=.*\d).{8,20}$/,
			"Password doesnt match requirements"
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), undefined], "password must match")
		.required(),
});

function ResetPassword(): FunctionComponent {
	const methods = useForm({
		resolver: yupResolver(resetPasswordSchema),
		mode: "onChange",
	});

	const navigate = useNavigate();

	const { token } = Route.useSearch();

	const resetPassword = useResetPassword();

	const newPasswordValue = methods.watch("newPassword");

	const [passwordRequirements, setPasswordRequirements] = useState({
		hasMinLength: "default",
		hasCapitalLetter: "default",
		hasNumber: "default",
	});

	useEffect(() => {
		if (newPasswordValue) {
			setPasswordRequirements({
				hasMinLength: newPasswordValue.length >= 8 ? "valid" : "invalid",
				hasCapitalLetter: /[A-Z]/.test(newPasswordValue) ? "valid" : "invalid",
				hasNumber: /\d/.test(newPasswordValue) ? "valid" : "invalid",
			});
		} else {
			setPasswordRequirements({
				hasMinLength: "default",
				hasCapitalLetter: "default",
				hasNumber: "default",
			});
		}
	}, [newPasswordValue]);

	useEffect(() => {
		if (!token) {
			setTimeout(() => {
				toast.error("token doesnt exist!");
				navigate({ to: "/" }).catch((error) => {
					console.error(error);
				});
			}, 2000);
		}
	}, []);

	const onSubmit = (data: yup.InferType<typeof resetPasswordSchema>): void => {
		const payload = {
			password: data.confirmPassword,
			token: token,
		};

		resetPassword.mutate(payload, {
			onSuccess: () => {
				toast.success("Set new password is successfully!");
				navigate({ to: "/login" }).catch((error) => {
					console.error(error);
				});
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message);
				} else {
					console.error("unexpected error:", error);
				}
			},
		});
	};

	return (
		<AuthLayout>
			<div className="flex flex-row flex-grow w-full justify-center items-center h-full">
				<div className="flex flex-col w-full h-screen justify-center items-center px-8">
					<div className="flex flex-col justify-center items-center w-full gap-4">
						<div className="p-2 border-2 text-grey-400 rounded">
							<LockKeyholeIcon />
						</div>

						<div className="flex flex-col justify-center items-center">
							<span className="font-semibold text-grey-800">
								Set New Password
							</span>
							<span className="text-sm text-grey-400">
								Must be at least 8 characters
							</span>
						</div>

						<div className="flex flex-col w-full gap-4">
							<FormProvider {...methods}>
								<div className="flex flex-col my-4 w-full gap-2">
									<InputForm
										type="password"
										name="newPassword"
										placeholder="New Password"
										label="New Password"
									/>
									<div className="flex flex-col gap-1 text-grey-300 text-sm">
										<span>Password must include:</span>
										<ul className="flex flex-col gap-1">
											<li
												className={`flex items-center gap-1 ${passwordRequirements.hasMinLength === "invalid" ? "text-red-500" : passwordRequirements.hasMinLength === "valid" ? "text-green-400" : ""}`}
											>
												{passwordRequirements.hasMinLength === "invalid" ? (
													<XIcon size={16} />
												) : (
													<CheckIcon size={16} />
												)}
												<span>8 - 20</span>
												<span className="font-semibold">characters</span>
											</li>
											<li
												className={`flex items-center gap-1 ${passwordRequirements.hasCapitalLetter === "invalid" ? "text-red-500" : passwordRequirements.hasCapitalLetter === "valid" ? "text-green-400" : ""}`}
											>
												{passwordRequirements.hasCapitalLetter === "invalid" ? (
													<XIcon size={16} />
												) : (
													<CheckIcon size={16} />
												)}
												<span>At least</span>
												<span className="font-semibold">
													one capital letter
												</span>
											</li>
											<li
												className={`flex items-center gap-1 ${passwordRequirements.hasNumber === "invalid" ? "text-red-500" : passwordRequirements.hasNumber === "valid" ? "text-green-400" : ""}`}
											>
												{passwordRequirements.hasNumber === "invalid" ? (
													<XIcon size={16} />
												) : (
													<CheckIcon size={16} />
												)}
												<span>At least</span>
												<span className="font-semibold">one number</span>
											</li>
										</ul>
									</div>
								</div>

								<InputForm
									type="password"
									name="confirmPassword"
									placeholder="New Password"
									label="Confirm The New Password"
								/>

								<div className="flex flex-col w-full gap-1">
									<Button
										size="md"
										variant="primary"
										onClick={(): void => {
											methods
												.handleSubmit(onSubmit)()
												.catch((error) => {
													console.error(error);
												});
										}}
										disabled={resetPassword.isPending}
										startIcon={
											resetPassword.isPending && (
												<LoaderCircleIcon className="animate-spin" />
											)
										}
									>
										Set New Password
									</Button>
									<Button
										size="md"
										variant="outline"
										onClick={(): void => {
											navigate({ to: "/forgot-password" }).catch((error) => {
												console.error(error);
											});
										}}
									>
										Cancel
									</Button>
								</div>
							</FormProvider>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}

export default ResetPassword;
