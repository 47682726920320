import CoinIcon from "@/assets/icons/coin-icon.png";
import { cn } from "@/common/helper";
import { LoaderCircleIcon } from "lucide-react";

interface SectionOverlayProps {
	overlay: boolean;
	isAnalyzing: boolean;
	setIsAnalyzing: (value: boolean) => void;
	analyzeInfluencer: () => void;
	children: React.ReactNode;
	displayType?: "public" | "analytics" | "er_calc";
}
const SectionOverlay: React.FC<SectionOverlayProps> = ({
	overlay,
	isAnalyzing,
	analyzeInfluencer,
	children,
	displayType,
}) => {
	return (
		<div className="relative mt-4">
			{/* Overlay */}
			{!overlay && displayType != "analytics" && (
				<div
					className="absolute flex w-[105%] h-[105%] z-99 rounded-lg border-2 border-red-500 float-right"
					style={{ left: "-2.5%", top: "-2.5%" }}
				>
					<span className="w-full text-right pr-4 pt-2 text-red-500">
						NOT THE ACTUAL RESULT
					</span>
				</div>
			)}
			<div
				className={cn(
					`absolute flex items-center justify-center w-[105%] h-[105%] z-99 bg-grey-800/40 backdrop-blur rounded-lg`,
					overlay ? `flex` : "hidden"
				)}
				style={{ left: "-2.5%", top: "-2.5%" }}
			>
				<div className="flex flex-col justify-center items-center gap-5">
					{isAnalyzing ? (
						<>
							<LoaderCircleIcon className="animate-spin text-white" size={50} />
							<div className="flex flex-col items-center gap-1 text-white text-lg">
								<span className="font-medium">Analyzing . . .</span>
								<span>
									This may take a while. We will send a notification to your
									email once it is finished.
								</span>
							</div>
							<button className="flex items-center w-fit bg-gradient-to-r from-orange-600 to-orange-400 hover:opacity-90 border border-white text-white font-medium py-4 px-10 gap-2 rounded-2xl">
								Analyzing List Progress
							</button>
						</>
					) : (
						<button
							className="flex items-center w-fit bg-gradient-to-r from-orange-600 to-orange-400 hover:opacity-90 border border-white text-white font-medium py-4 px-10 gap-2 rounded-2xl"
							onClick={() => {
								analyzeInfluencer();
							}}
						>
							<img
								src={CoinIcon}
								alt="icon"
								className="w-5 h-5 border border-white rounded-full"
							/>
							<span>Analyze</span>
						</button>
					)}
				</div>
			</div>

			{/* Section Content */}
			{children}
		</div>
	);
};

export default SectionOverlay;
