import Register from "@/pages/auth/register";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/register")({
	component: Register,
	beforeLoad: ({ location }) => {
		if (tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login", search: location.href });
		}
	},
});
