import { TriangleAlert } from "lucide-react";
import { CheckmarkIcon, toast, type ToastOptions } from "react-hot-toast";

interface CustomToastProps {
	title: string;
	description: string;
}
export const customToast = (
	props: CustomToastProps,
	type?: "success" | "error" | "warning",
	options?: ToastOptions
): void => {
	if (type == "success") {
		options = {
			icon: <CheckmarkIcon></CheckmarkIcon>,
			style: {
				background: "#F0FFE5",
				maxWidth: "400px",
			},
			iconTheme: {
				primary: "#55A61C",
				secondary: "white",
			},
			duration: 5000,
		};
	} else if (type == "warning") {
		options = {
			icon: <TriangleAlert color="#FAA61A"></TriangleAlert>,
			style: {
				background: "#FFEED7",
				maxWidth: "400px",
			},
			iconTheme: {
				primary: "#FAA61A",
				secondary: "white",
			},
			duration: 5000,
		};
	} else if (type == "error") {
		options = {
			icon: <TriangleAlert color="#DC0000"></TriangleAlert>,
			style: {
				background: "#FDEDED",
				maxWidth: "400px",
			},
			iconTheme: {
				primary: "#DC0000",
				secondary: "white",
			},
			duration: 5000,
		};
	}
	toast(
		() => (
			<div style={{ maxWidth: 350 }}>
				<div className="font-semibold text-grey-800">{props.title}</div>
				<span>{props.description}</span>
			</div>
		),
		options
	);
};
